import React, { useState } from 'react'
import Modal from './Modal'
import { useTranslation } from 'react-i18next';
import { TriangleAlert } from 'lucide-react';
import supabase from '../utils/supabase';
import { sendNotify } from '../utils/functions';

export default function SetPassword() {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false)
    const [password, setPassword] = useState<string>('')
    const [password2, setPassword2] = useState<string>('')
    const [loginLoad, setLoginLoad] = useState(false)
    const [message, setMessage] = useState<string>('')

    const handlePassword = (event:any) => {
        setPassword(event.target.value);
    }
    const handlePassword2 = (event:any) => {
        setPassword2(event.target.value);
    }
    const sendForm = async (event:any) => {
        event.preventDefault()
        setLoginLoad(true)
        setMessage('')

        if (password!==password2) {
            setMessage('Passwords don\'t match')
        }
        else {
            const { data, error } = await supabase.auth.updateUser({
                password: password,
            })
            console.log(data, error);
            
            if (error) {
                setMessage(error.message)
            }
            else {
                setOpen(false);
                setPassword('');
                setPassword2('');
                sendNotify(t('saved'))
            }
        }
        setLoginLoad(false)
    }
    return (
        <div>
            <div className='flex justify-end'>

                <button className='btn btn-sm btn-primary' onClick={()=>setOpen(true)}>{t('setPassword')}</button>
            </div>
            <Modal isModalOpen={open} setIsModalOpen={()=>setOpen(false)} title={t('password')}>
                <div className='flex flex-col items-center text-center w-full'>
                    {message && <p className='flex gap-2 mb-3'><TriangleAlert />{message}<TriangleAlert /></p> }
                    <form onSubmit={sendForm} className='flex flex-col gap-3 w-96'>
                        <input type="password" name="password1" className='input input-bordered w-full' placeholder={t("password")} value={password} onChange={handlePassword}/>
                        <input type="password" name="password2" className='input input-bordered w-full' placeholder={t("password2")} value={password2} onChange={handlePassword2}/>
                        <button className='btn btn-primary'>{loginLoad?'loading..':'Login'}</button>
                    </form>
                </div>
            </Modal>
        </div>
    )
}
