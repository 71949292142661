import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
/* Q4
CraftBills bietet verschiedene Preispläne, die auf die Bedürfnisse von Kleinunternehmern und Freelancern zugeschnitten sind. Es gibt auch eine kostenlose Testversion, damit du alle Funktionen ausprobieren und sehen kannst, wie CraftBills dir helfen kann, bevor du dich entscheidest. */

interface FAQItemProps {
    question: string;
    answer: JSX.Element;
}


const FaqItem = ({ item }: { item: FAQItemProps }) => {
    const accordion = useRef<any>(null);
    const [isOpen, setIsOpen] = useState(false);

    return (
        <li>
            <button className="relative flex gap-2 items-center w-full py-5 text-base font-semibold text-left border-t md:text-lg border-base-content/10" onClick={(e) => { e.preventDefault(); setIsOpen(!isOpen); }} aria-expanded={isOpen} >
                <span className={`flex-1 text-base-content ${isOpen ? "text-primary" : ""}`} >{item?.question}</span>
                <svg className={`flex-shrink-0 w-4 h-4 ml-auto fill-current`} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" >
                <rect y="7" width="16" height="2" rx="1" className={`transform origin-center transition duration-200 ease-out ${isOpen && "rotate-180" }`} />
                <rect y="7" width="16" height="2" rx="1" className={`transform origin-center rotate-90 transition duration-200 ease-out ${isOpen && "rotate-180 hidden" }`} />
                </svg>
            </button>

            <div ref={accordion} className={`transition-all duration-300 ease-in-out opacity-80 overflow-hidden`} style={ isOpen ? { maxHeight: accordion?.current?.scrollHeight, opacity: 1 } : { maxHeight: 0, opacity: 0 } } >
                <div className="pb-5 leading-relaxed">{item?.answer}</div>
            </div>
        </li>
    );
};

const FAQ = () => {
    const { t } = useTranslation();
    const faqList: FAQItemProps[] = [
        {question:t("question1"),answer:<div className="space-y-2 leading-relaxed">{t("answer1")}</div>},
        {question:t("question2"),answer:<div className="space-y-2 leading-relaxed">{t("answer2")}</div>},
        {question:t("question3"),answer:<div className="space-y-2 leading-relaxed">{t("answer3")}</div>},
        {question:t("question4"),answer:<div className="space-y-2 leading-relaxed">{t("answer4")}</div>},
    ]

    return (
        <section className="bg-base-200" id="faq">
            <div className="py-24 px-8 max-w-7xl mx-auto flex flex-col md:flex-row gap-12">
                <div className="flex flex-col text-left basis-1/2">
                    <p className="inline-block font-semibold text-primary mb-4">FAQ</p>
                    <p className="sm:text-4xl text-3xl font-extrabold text-base-content">Frequently Asked Questions</p>
                </div>

                <ul className="basis-1/2">
                    {faqList.map((item, i) => (
                        <FaqItem key={i} item={item} />
                    ))}
                </ul>
            </div>
        </section>
    );
};

export default FAQ;
