import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import config from './config';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

document.documentElement.setAttribute('data-theme', config.colors.theme);

root.render(
    // <html lang="en" data-theme={config.colors.theme}>
    // <html lang="en" data-theme={config.colors.theme}>
        <React.StrictMode>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </React.StrictMode>
    // </html>
);
