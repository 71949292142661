// import AnalyticsGraph from '../components/AnalyticsGraph';
// import { useContext, useEffect } from 'react';
import Analytics from '../components/Analytics';
import Layout from '../components/Layout';
import OpenInvoices from '../components/OpenInvoices';
// import { userContext } from '../App';
// import { useNavigate } from 'react-router-dom';
import Changelog from '../components/Changelog';

// https://preline.co/index.html

export default function Dashboard() {
    // const [, , isPremium] = useContext(userContext);
    // const navigate = useNavigate()

    // useEffect(()=>{
    //     if (!isPremium) navigate('/#pricing')
    // },[isPremium,navigate])

    return (
        <Layout>
            <div className='flex flex-col md:flex-row gap-4 w-full'>
                <div className='flex flex-col w-full md:w-5/12 gap-4'>
                    <Analytics />
                    <OpenInvoices />
                </div>
                <div className='w-full md:w-7/12'>
                    {/* <AnalyticsGraph /> */}
                    <Changelog />
                </div>
            </div>
        </Layout>
    )
}
