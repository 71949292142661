import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { sendError } from '../utils/functions';
import supabase from '../utils/supabase';
import { Clock } from 'lucide-react';
import { HashLink as Link } from 'react-router-hash-link';

export default function InvoiceReminder({invoice}:{invoice:Invoices}) {
    const { t } = useTranslation();
    const [reminderText, setReminderText] = useState('')
    const [noReminderSet, setNoReminderSet] = useState(false)

    useEffect(()=>{
        const getData = async () => {
            const {data, error} = await supabase.from('users').select('template_reminder').eq('id', invoice.users.id).single();
            if (data?.template_reminder) {
                const dateNormalTmp = new Date(invoice.created_at);
                const dateNormal: any = dateNormalTmp.getDate().toString().padStart(2, '0')+'.'+(dateNormalTmp.getMonth() + 1).toString().padStart(2, '0')+'.'+dateNormalTmp.getFullYear();

                const newText = data.template_reminder
                    .replaceAll('[invoice.createdat]',dateNormal)
                    .replaceAll('[invoice.total]',invoice.total)
                    .replaceAll('[invoice.payment_days]',invoice.payment_days)
                    .replaceAll('[invoice.id]',invoice.description)
                    .replaceAll('[user.bank]',invoice.users.bank)
                    .replaceAll('[user.bankaccount]',invoice.users.bankaccount)
                    .replaceAll('[user.phone]',invoice.users.phone)
                    .replaceAll('[user.email]',invoice.users.email)
                    .replaceAll('[user.name]',invoice.users.name)
                    .replaceAll('[client.name]',invoice.address.name)
                setReminderText(newText);
            }
            else if (error) {
                sendError(error);
            }
            else {
                setNoReminderSet(true);
            }
        }
        if (paymentDue(invoice.created_at,invoice.payment_days)) {
            getData();
        }
    },[invoice])



    const paymentDue = (date:string,days:number) => {
        const today = new Date()
        const paymentDueDate = new Date(date);
        paymentDueDate.setDate(paymentDueDate.getDate() + 0);
        if (paymentDueDate<today) return true;
        else return false;
    }
    const handleReminderEmail = () => {
        const email = (invoice.clients?.email)?invoice.clients?.email:'';
        const subject = t('paymentReminder');
        const body:string = reminderText.replaceAll('\n','%0A');
        window.location.href = 'mailto:'+email+'?subject='+subject+'&body='+body
    }
    return noReminderSet ? (
        <Link to="/settings#templates" className='btn flex gap-2'>{t('setReminder')}</Link>
    ) : (
        <button className={'btn flex gap-2'+(!paymentDue(invoice.created_at,invoice.payment_days)?' opacity-20':'')} onClick={handleReminderEmail} disabled={!paymentDue(invoice.created_at,invoice.payment_days)}><Clock /> {t('reminder')}</button>
    )
}
