import React from 'react'
import { useTranslation } from 'react-i18next';

export default function LinkPath({path}:{path:any[]}) {
    const { t } = useTranslation();
    return (
        <div className="breadcrumbs text-sm">
            <ul>
                <li>
                    <a href='/home'>Home</a>
                </li>
                {path.map((pathItem:any,index)=>{
                    if (path[path.length-1]===pathItem) return <span key={JSON.stringify(index)} className="inline-flex items-center gap-2">{t(pathItem.name)}</span>
                    else return <li><a key={JSON.stringify(index)} href={'/'+pathItem.link}>{t(pathItem.name)}</a></li>
                })}
            </ul>
        </div>
    )
}
