import React from 'react'
// import { jsPDF } from 'jspdf';
// import autoTable, { CellInput } from 'jspdf-autotable'
import { useTranslation } from 'react-i18next';
// import { imgData } from '../img/imgBase64';
import { generatePDF } from '../utils/functions';

// https://www.npmjs.com/package/jspdf
// https://www.npmjs.com/package/jspdf-autotable

export default function PdfGenerator({invoice,status}:{invoice:Invoices,status:number}) {
    const { t } = useTranslation();
    // function getDate(UNIX_timestamp:number){
    //     let yourDate = new Date(UNIX_timestamp)
    //     var test = yourDate.toISOString().split('T')[0]
    //     return test;
    // }
    // const generatePDF = (view:boolean) => {
    //     // NOTE: bofore making changes, check for invoice.marker_for_pdf
    //     const doc = new jsPDF();
    //     const currency=' '+invoice.users.currency
        
    //     // SECTION: Preview water mark
    //     if (view&&status<10) {
    //         doc.setFontSize(50);
    //         doc.setTextColor(150);
    //         doc.text('PREVIEW',20, 30,{angle:-45});
    //         doc.text('PREVIEW',120, 30,{angle:-45});
    //         doc.setTextColor(0);
    //     }
    //     // SECTION: data client and user
    //     autoTable(doc, {startY: 25,
    //         theme:'plain',
    //         head:[[invoice.clients.name, invoice.users.name]],
    //         body: [
    //             [
    //                 invoice.clients.street+' '+invoice.clients.streetnumber+'\n'+
    //                 invoice.clients.zip+' '+invoice.clients.city+'\n'+
    //                 invoice.clients.country+'\n'+
    //                 ((invoice.clients.ust_id)?t('taxId')+': '+invoice.users.ust_id+'\n':'')
    //                 ,
    //                 invoice.users.street+' '+invoice.users.streetnumber+'\n'+
    //                 invoice.users.zip+' '+invoice.users.city+'\n'+
    //                 invoice.users.country+'\n'+
    //                 ((invoice.users.ust_id)?t('taxId')+': '+invoice.users.ust_id+'\n':'')+
    //                 t('taxNumber')+': '+invoice.users.tax_id
    //             ]
    //           // ...
    //         ],
    //         columnStyles: {
    //             0: { halign: 'left', cellPadding: 0, textColor: 'rgb(75, 85, 99)', cellWidth:125},
    //             1: { halign: 'left', cellPadding: 0, textColor: 'rgb(75, 85, 99)'},
    //         },
    //         headStyles :{
    //             cellPadding: 0,
    //             textColor:'rgb(55, 65, 81)'
    //         }
    //     })
    //     // SECTION: invoice id and date
    //     autoTable(doc, {
    //         theme:'plain',
    //         startY: 75,
    //         head: [[t('invoice')+':','']],
    //         body: [
    //           [t('invoiceID')+": "+invoice.description, t('invoiceDate')+': '+getDate(Date.parse(invoice.created_at))],
    //         ],
    //         columnStyles: {
    //             0: { halign: 'left', cellPadding: 0 },
    //             1: { halign: 'right', cellPadding: 0 },
    //         },
    //         headStyles :{
    //             cellPadding: 0,
    //             fontSize:16
    //         }
    //     })

    //     // SECTION: create rows for positions
    //     const arrayPositions:any[][] = [];
    //     let totalCosts = 0;
    //     invoice.positions.forEach((position:any,index:number) => {
    //         const newArr:CellInput = [
    //             index+1,
    //             position.description,
    //             // position.amount.toFixed(2),
    //             allToFixed(position.amount),
    //             // position.price.toFixed(2)+currency,
    //             allToFixed(position.price)+currency,
    //             // (position.amount*position.price).toFixed(2)+currency
    //             allToFixed(position.amount*position.price)+currency,
    //         ]
    //         arrayPositions.push(newArr);
    //         totalCosts=totalCosts+(position.amount*position.price);
    //     });
    //     const tax=invoice.no_tax?0:invoice.tax_percent;
    //     const zwischenRow = [
    //         '',
    //         '',
    //         '',
    //         '\n'+t('subTotal')+':\n\n'+t('tax')+' ('+tax+'%):\n\n'+t('total')+':',
    //         // '\n'+totalCosts.toFixed(2)+currency+'\n\n'+(totalCosts*(tax/100)).toFixed(2)+currency+'\n\n'+(totalCosts+(totalCosts*(tax/100))).toFixed(2)+currency
    //         '\n'+allToFixed(totalCosts)+currency+'\n\n'+allToFixed(totalCosts*(tax/100))+currency+'\n\n'+allToFixed(totalCosts+(totalCosts*(tax/100)))+currency
    //     ]
    //     arrayPositions.push(zwischenRow);
        
    //     // SECTION: positions table
    //     autoTable(doc, {
    //         startY: 90,
    //         head: [['Pos', t('description'), t('amount'), t('price'), t('total')]],
    //         body: arrayPositions,
    //         columnStyles: {
    //             0: { halign: 'left', cellWidth:10 },  // Position
    //             1: { halign: 'left' },  // Bezeichnung
    //             2: { halign: 'right', cellWidth:25 }, // Menge
    //             3: { halign: 'right', cellWidth:32 }, // Preis
    //             4: { halign: 'right', cellWidth:25 }  // Gesamt
    //         },
    //         didParseCell: function (data) {
    //             if (data.row.index === 0 && (data.column.index === 2 || data.column.index === 3 || data.column.index === 4)) {
    //                 data.cell.styles.halign = 'right';
    //             }
    //             if (data.row.index === data.table.body.length - 1) {
    //                 data.cell.styles.fillColor=255
    //             }
                
    //         },
    //     })

    //     // SECTION: get height of table
    //     let tablePositionFinalY = (doc as any).lastAutoTable.finalY;
    //     tablePositionFinalY=Number(tablePositionFinalY.toFixed());
        
    //     // SECTION: new page if tablePositionFinalY>=225
    //     if (tablePositionFinalY>=225) {
    //         doc.addPage()
    //         if (view&&status<10) {
    //             doc.setFontSize(50);
    //             doc.setTextColor(150);
    //             doc.text('PREVIEW',20, 30,{angle:-45});
    //             doc.text('PREVIEW',120, 30,{angle:-45});
    //             doc.setTextColor(0);
    //         }
    //         tablePositionFinalY=50
    //     }
    
    //     // Extra text
    //     doc.setFontSize(10);
    //     doc.setFont('helvetica', 'bold');
    //     doc.text(t('paymentTerms'),14,tablePositionFinalY+15)
    //     doc.setFont('helvetica', 'normal');
    //     doc.text(t('paymentInfo',{days:invoice.payment_days}),14,tablePositionFinalY+20)
    //     if (invoice.no_tax) {
    //         doc.text(t('noTaxMessage'),14,tablePositionFinalY+30)
    //     }
        
    //     const bankDetails = t('recipient')+': '+invoice.users.name+'\n'+t('bank')+': '+invoice.users.bank+'\n'+t('bankaccount')+': '+invoice.users.bankaccount;

    //     // SECTION: add bank details and page number everywhere
    //     for (let i = 1; i <= doc.getNumberOfPages(); i++) {
    //         // doc.addImage(imgData, 'png', 177, 284, 19, 6)
    //         doc.setPage(i)
    //         doc.addImage(imgData, 'png', 177, 10, 19, 6)
    //         doc.setFontSize(10);
    //         doc.line(14, 260, 196, 260);
    //         doc.setFont('helvetica', 'bold');
    //         doc.text(t('bankDetails'),14,270)
    //         doc.setFont('helvetica', 'normal');
    //         doc.text(bankDetails,14,275)
    //         doc.text(t('page')+' '+i+'/'+doc.getNumberOfPages(), 180, 283)
    //     }
        
    //     // Open the PDF
    //     if (view) {
    //         const pdfBlob = doc.output('blob');
    //         const pdfUrl = URL.createObjectURL(pdfBlob);
    //         window.open(pdfUrl, '_blank');
    //     }
    //     // Save the PDF
    //     else {
    //         doc.save(invoice.description+".pdf");
    //     }
    // };
    return (
        <>
            {status>=10 && <button className='btn hover:btn-primary w-full md:max-w-44' onClick={()=>generatePDF(invoice,status,false)}>{t('pdfDownload')}</button> }
            <button className='btn hover:btn-primary w-full md:max-w-40' onClick={()=>generatePDF(invoice,status,true)}>{t('pdfView')}</button>
        </>
    )
}
