export default function Loading({table}:{table?:boolean}) {
    return (
        <div className='flex flex-col items-center w-full p-2'><div className="spinner-loader spinner-normal"></div></div>
    )
    // if (table) {
    //     return (
    //         <div className='flex flex-col items-center w-full p-2'>
    //             <div className="flex w-full max-w-7xl flex-col gap-4">
    //                 {/* <div className="skeleton h-32 w-full"></div> */}
    //                 <div className="skeleton h-4 w-28"></div>
    //                 <div className="skeleton h-4 w-28"></div>
    //                 <div className="skeleton h-4 w-full"></div>
    //                 <div className="skeleton h-4 w-full"></div>
    //                 <div className="skeleton h-4 w-full"></div>
    //                 <div className="skeleton h-4 w-full"></div>
    //             </div>
    //         </div>
    //     )
        
    // }
    // else {
    //     return (
    //         <div className='flex flex-col items-center w-full p-2'><div className="spinner-loader spinner-normal"></div></div>
    //     )
    // }
}