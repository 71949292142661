import React, { useState } from 'react'
import supabase from '../../utils/supabase';
import { useNavigate } from 'react-router-dom';
import ClientEditNew from '../../components/ClientEditNew';
import { useTranslation } from 'react-i18next';
import { sendError } from '../../utils/functions';
import Layout from '../../components/Layout';
import LinkPath from '../../components/LinkPath';

export default function ClientNew({user_id}:{user_id:string}) {
    const { t } = useTranslation();
    const navigate = useNavigate()
    const [client, setClient] = useState<Clients>({
        name: '',
        email: '',
        phone: '',
        street: '',
        streetnumber: '',
        zip: '',
        city: '',
        country: '',
        info: '',
        ust_id: ''
    });

    const handleEdit = (e:any) => {
        setClient({
            ...client,
            [e.target.name]: e.target.value
        });
    }
    const save = async (e:any) => {
        e.preventDefault()
        const {data, error} = await supabase.from('clients').insert({
            ...client,
            user_id:user_id
        }).select().single();
        if (data) {
            navigate('/clients/'+data.id)
        }
        else {
            sendError(error);
        }
    }
    return (
        <Layout>
            <LinkPath path={[{'name':'clients','link':'clients'},{'name':'new','link':'clients'}]}/>
            <h1 className="text-3xl md:text-4xl font-extrabold">{t('newClient')}</h1>
            <ClientEditNew client={client} handleEdit={handleEdit} save={save} />
        </Layout>
    )
}
