import { useContext, useEffect } from 'react';
import { userContext } from '../../../App';
import FAQ from './FAQ';
import FeaturesAccordion from './FeaturesAccordion';
import Footer from './Footer';
import Hero from './Hero';
import LoginStart from './LoginStart';
// import Pricing from './Pricing';
import { useLocation } from 'react-router-dom';
import config from '../../../config';
import { useTranslation } from 'react-i18next';

export default function LandingPage() {
    const { t } = useTranslation();
    const [login] = useContext(userContext);
    const { hash } = useLocation();
    useEffect(() => {
        document.title = config.appName+" | "+t('titleAdder');
    }, [t]);
    useEffect(() => {
        if (hash==='#pricing') {
            const element = document.getElementById(hash.replace('#', ''));
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [hash]);

    return (
        <>
            <Hero />
            <FeaturesAccordion />
            <FAQ />
            { !login&& <LoginStart /> }
            {/* <Pricing /> */}
            <Footer />
        </>
    )
}
