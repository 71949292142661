import React, { useContext, useEffect, useState } from 'react'
import supabase from '../utils/supabase';
import Modal from './Modal';
import { useTranslation } from 'react-i18next';
import { LogIn, LogOut } from 'lucide-react';
import { userContext } from '../App';

export default function Login({bigBtn}:{bigBtn?:boolean}) {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState(t('registerInfo'));
    const [loginLoad, setLoginLoad] = useState(false);
    const [login,setLogin] = useContext(userContext);

    useEffect(()=>{;
        supabase.auth.onAuthStateChange((event, session) => {
            if (session) {
                setLogin(session.user.id);
            }
            else setLogin('');
        })
    },[setLogin])
    

    const handlePassword = (event:any) => {
        setPassword(event.target.value);
    }
    const handleEmail = (event:any) => {
        setEmail(event.target.value);
    }
    const sendLogin = async (event:any) => {
        setLoginLoad(true)
        event.preventDefault()
        if (password==='') {
            const { data, error } = await supabase.auth.signInWithOtp({
                email: email,
                options: {
                    emailRedirectTo: window.location.origin+'/home'
                },
            })
            if (data) {
                setMessage(t('msgAfterLogin'))
            }
            else {
                console.log(data, error);
            }
        }
        else {
            const { data, error } = await supabase.auth.signInWithPassword({
                email: email,
                password: password
            })
            if (error) {
                setMessage(error.message)
            }
            else {
                setOpen(false);
                setEmail('');
                setPassword('');
            }
            
        }
        setLoginLoad(false)
    }
    const logoutUser = async () => {
        let { error } = await supabase.auth.signOut()   
        console.log(error);
    }
    return (
        <div>
            {(login)?
            <button className="btn btn-primary ml-5" onClick={logoutUser}><LogOut/> Logout</button>
            :
            (bigBtn) ? 
            <button className="btn btn-primary -round" onClick={()=>setOpen(true)}>{t('startNow')}</button>
            :
            <button className="btn btn-primary ml-5" onClick={()=>setOpen(true)}><LogIn /> Login</button>
            }
            <Modal isModalOpen={open} setIsModalOpen={()=>setOpen(false)} title='Login'>
                <div className='flex flex-col items-center text-center w-full'>
                    {message && <p className='mb-3'>{message}</p> }
                    <form onSubmit={sendLogin} className='flex flex-col gap-3 w-96'>
                        <input type="email" name="email" className='input input-bordered w-full' placeholder={t("email")} value={email} onChange={handleEmail}/>
                        <input type="password" name="password" className='input input-bordered w-full' placeholder={t("password")} value={password} onChange={handlePassword}/>
                        <button className='btn btn-primary'>{loginLoad?'loading..':'Login'}</button>
                    </form>
                </div>
            </Modal>
        </div>
    )
}
