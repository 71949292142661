
// @ts-ignore
import logo from "../../../img/logo.png";

import { Link } from "react-router-dom";
import config from "../../../config";
import { useTranslation } from "react-i18next";

// Add the Footer to the bottom of your landing page and more.
// The support link is connected to the config.js file. If there's no config.mailgun.supportEmail, the link won't be displayed.
const Footer = () => {
    const { t } = useTranslation();
    return (
        <footer className="bg-base-200 border-t border-base-content/10">
            <div className="max-w-7xl mx-auto px-8 py-24">
                <div className=" flex lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col">
                    <div className="w-64 flex-shrink-0 md:mx-0 mx-auto text-center md:text-left">
                        <Link to="/#" aria-current="page" className="flex gap-2 justify-center md:justify-start items-center" >
                            <img src={logo} alt={`${config.appName} logo`} className="w-6 h-6" width={24} height={24} />
                            <strong className="font-extrabold tracking-tight text-base md:text-lg">
                                {config.appName}
                            </strong>
                        </Link>
                        <p className="mt-3 text-sm text-base-content/80">{config.appDescription}</p>
                        <p className="mt-3 text-sm text-base-content/60">Copyright © {new Date().getFullYear()}</p>
                    </div>
                    <div className="flex-grow flex flex-wrap justify-center -mb-10 md:mt-0 mt-10 text-center">
                        <div className="lg:w-1/3 md:w-1/2 w-full px-4">
                            <div className="footer-title font-semibold text-base-content tracking-widest text-sm md:text-left mb-3">LINKS</div>
                            <div className="flex flex-col justify-center items-center md:items-start gap-2 mb-10 text-sm">
                                <a href={`mailto:contact@craftbills.com`} className="link link-hover" aria-label="Contact Support" >Support</a>
                                {/* <Link to="/#pricing" className="link link-hover">{t('price')}</Link> */}
                            </div>
                        </div>
                        <div className="lg:w-1/3 md:w-1/2 w-full px-4">
                            <div className="footer-title font-semibold text-base-content tracking-widest text-sm md:text-left mb-3">LEGAL</div>
                            <div className="flex flex-col justify-center items-center md:items-start gap-2 mb-10 text-sm">
                                <Link to="/tos" className="link link-hover">{t('tos')}</Link>
                                <Link to="/privacy-policy" className="link link-hover">{t('privacyPolicy')}</Link>
                                <Link to="/imprint" className="link link-hover">{t('imprint')}</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
