import React, { useEffect, useState } from 'react'
import supabase from '../utils/supabase';
import { sendError } from '../utils/functions';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export default function OpenInvoices() {
    const { t } = useTranslation();
    const [invoices, setInvoices] = useState<Invoices[]>();
    useEffect(()=>{
        const getInvoice = async () => {
            const {data,error} = await supabase.from('invoices').select('id, description, status, created_at, address').lt('status', 30).order('created_at', { ascending: false });
            if (data) {
                setInvoices(data);
            }
            else {
                sendError(error);
            }
        }
        getInvoice()
    },[])

    return (
        <div className="bg-base-300 text-primary-contents w-full p-4 overflow-auto rounded-2xl">
            <table className="table table-zebra">
                <thead>
                    <tr>
                        <th>{t('invoiceID')}</th>
                        <th>{t('client')}</th>
                    </tr>
                </thead>
                <tbody>
                    {invoices?.map(invoice=>{
                        return <tr key={invoice.id}>
                            <td><Link to={'/invoices/'+invoice.id}>{invoice.description}</Link></td>
                            <td>{invoice.address.name}</td>
                        </tr>
                    })}
                </tbody>
            </table>
        </div>
    )
}
