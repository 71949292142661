import React from 'react'

export default function Changelog() {
    return (
        <div className="bg-base-300 text-primary-contents w-full p-4 rounded-2xl">
            <h1 className="text-xl font-extrabold mb-5">Changelog</h1>
            <div className='h-52 max-h-52 overflow-auto'>
                <div className='mt-5'>
                    <p>2024-09-12</p>
                    <ul className='list-disc list-inside'>
                        <li>Ausgaben Modul</li>
                    </ul>
                </div>
                <div className='mt-5'>
                    <p>2024-08-22</p>
                    <ul className='list-disc list-inside'>
                        <li>Zeilenumbruch in Rechnungstext</li>
                        <li>Vorlage für abschließenden Text</li>
                        <li>Möglichkeit Passwort zu setzen</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
