// import themes from "daisyui/src/theming/themes.js";
// @ts-ignore
import i18next from 'i18next';

const config = {
    // REQUIRED
    appName: "CraftBills",
    // REQUIRED: a short description of your app for SEO tags (can be overwritten)
    appDescription:"Invoices in seconds",
    // REQUIRED
    domainName: "http://localhost:3000",
    backendDomain:"http://localhost:4000",
    stripe: {
        // Create multiple plans in your Stripe dashboard, then add them here. You can add as many plans as you want, just make sure to add the priceId
        plans: [
            {
                priceId:
                process.env.NODE_ENV === "development"
                    ? "price_1PfMilKEBgBAdcdpoDci0bk1"
                    : "need_to_be_configured_in_prod",
                // This plan will look different on the pricing page, it will be highlighted. You can only have one plan with isFeatured: true
                isFeatured: true,
                name: "CraftBills",
                // description: "OK plan",
                price: 14.99,
                // priceAnchor: 149,
                features: [
                    { name: i18next.t("feature1") },
                    { name: i18next.t("feature2") },
                    { name: i18next.t("feature3") },
                    { name: i18next.t("feature4") },
                ],
            },
        ],
    },
    colors: {
        // REQUIRED — The DaisyUI theme to use (added to the main layout.js). Leave blank for default (light & dark mode). If you any other theme than light/dark, you need to add it in config.tailwind.js in daisyui.themes.
        theme: "light",
        // REQUIRED — This color will be reflected on the whole app outside of the document (loading bar, Chrome tabs, etc..). By default it takes the primary color from your DaisyUI theme (make sure to update your the theme name after "data-theme=")
        // OR you can just do this to use a custom color: main: "#f37055". HEX only.
        // main: themes['[data-theme=dark]']["primary"],
    },
    auth: {
        // REQUIRED — the path to log in users. It's use to protect private routes (like /dashboard). It's used in apiClient (/libs/api.js) upon 401 errors from our API
        // loginUrl: "/sign-in",
        // REQUIRED — the path you want to redirect users after successfull login (i.e. /dashboard, /private). This is normally a private page for users to manage their accounts. It's used in apiClient (/libs/api.js) upon 401 errors from our API & in ButtonSignIn.js
        // callbackUrl: "/home",
    },
};

export default config;
