import React, { useEffect, useState } from 'react'
import supabase from '../../utils/supabase';
import Loading from '../../components/Loading';
import { useTranslation } from 'react-i18next';
import { allToFixed, sendError } from '../../utils/functions';
import { Link } from 'react-router-dom';
import Layout from '../../components/Layout';
import StatusIndicator from '../../components/StatusIndicator';
import LinkPath from '../../components/LinkPath';
import { Plus, SquareArrowOutUpRight } from 'lucide-react';
// import { userContext } from '../../App';

// npm install --save react-to-pdf
/* NOTE:
status=0 // draft
status=10 // created
status=20 // sent (not active)
status=30 // paid
status=40 // rejected
*/

export default function Invoices() {
    const { t } = useTranslation();
    const [loaded, setLoaded] = useState(false);
    const [invoices, setInvoices] = useState<Invoices[]>();
    // const [, , isPremium] = useContext(userContext);
    // const navigate = useNavigate()

    // useEffect(()=>{
    //     if (!isPremium) navigate('/#pricing')
    // },[isPremium,navigate])

    useEffect(()=>{
        const getInvoice = async () => {
            const {data,error} = await supabase.from('invoices').select('id, description, status, total, created_at, address, users(currency)').lt('status',100).order('created_at', { ascending: false });
            if (data) {                
                setInvoices(data);
            }
            else {
                sendError(error);
            }
            setLoaded(true);
        }
        getInvoice()
    },[])

    return loaded ? (
        <Layout>
            <LinkPath path={[{'name':'invoices','link':'invoices'}]}/>
            <div className='flex justify-between'>
                <h1 className="text-3xl md:text-4xl font-extrabold">{t('invoices')}</h1>
                <Link className='btn btn-sm btn-primary btn-circle' to={'/invoices/new'}><Plus /></Link>
            </div>
            <div className='overflow-y-auto'>
                <table className="table table-zebra">
                    <thead>
                        <tr>
                            <th></th>
                            <th>{t('invoiceID')}</th>
                            <th>{t('client')}</th>
                            <th>{t('total')}</th>
                            <th>{t('status')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {invoices?.map((invoice:any) =>{
                            return <tr key={invoice.id} className="hover">
                                <td>
                                    <Link className='btn btn-xs btn-primary' to={"/invoices/"+invoice.id}>
                                        <SquareArrowOutUpRight size={16} />
                                    </Link>
                                </td>
                                <td>{invoice.description}</td>
                                <td>{invoice.address.name}</td>
                                <td>{allToFixed(invoice.total)}</td>
                                <td><StatusIndicator status={invoice.status}/></td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
        </Layout>
    ) : (
        <Loading />
    )
}
