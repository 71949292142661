import React, { useEffect, useState } from 'react'
import supabase from '../../utils/supabase';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ClientEditNew from '../../components/ClientEditNew';
import { sendError } from '../../utils/functions';
import LinkPath from '../../components/LinkPath';
import Layout from '../../components/Layout';

export default function ClientEdit() {
    const { t } = useTranslation();
    const navigate = useNavigate()
    const {id:client_id} = useParams()
    const [client, setClient] = useState<Clients>({
        name: '',
        email: '',
        phone: '',
        street: '',
        streetnumber: '',
        zip: '',
        city: '',
        country: '',
        info: '',
        tax_id: ''
    });

    useEffect(()=>{;
        const getData = async () => {
            const {data, error} = await supabase.from('clients').select('*').eq('id', client_id).single();
            if (data) {
                setClient(data);
            }
            else {
                sendError(error);
            }
        }
        getData();
    },[client_id])

    const handleEdit = (e:any) => {
        setClient({
            ...client,
            [e.target.name]: e.target.value
        });
    }
    const save = async (e:any) => {
        e.preventDefault()
        const {data, error} = await supabase.from('clients').update(client).eq('id', client_id).select('*').single();
        if (data) {
            navigate('/clients/'+data.id)
        }
        else {
            // sendNotify(t('saved'),true)
            sendError(error);
        }
    }
    return (
        <Layout>
            <LinkPath path={[{'name':'clients','link':'clients'},{'name':client.name,'link':'clients/'+client.id},{'name':'edit','link':'clients'}]}/>
            <h1 className="text-3xl md:text-4xl font-extrabold">{t('editClient')}</h1>
            <ClientEditNew client={client} handleEdit={handleEdit} save={save} />
        </Layout>
    )
}
