import React, { useEffect, useState } from 'react'
import supabase from '../../utils/supabase';
import { useTranslation } from 'react-i18next';
import { sendError, sendNotify } from '../../utils/functions';
import { ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Save } from 'lucide-react';
import Layout from '../../components/Layout';
// import { userContext } from '../../App';
import SetPassword from '../../components/SetPassword';

export default function Settings({user_id}:{user_id:string}) {
    const { t } = useTranslation();
    const navigate = useNavigate()
    const [reload, setReload] = useState(false)
    const [user, setUser] = useState<Users>({
        name: '',
        email: '',
        street: '',
        streetnumber: '',
        zip: '',
        city: '',
        bank: '',
        bankaccount: '',
        currency: ''
    });
    // const [, , isPremium] = useContext(userContext);
    // useEffect(()=>{
    //     if (!isPremium) navigate('/#pricing')
    // },[isPremium,navigate])

    useEffect(()=>{
        const getData = async () => {
            const {data, error} = await supabase.from('users').select('*').eq('id', user_id).single();
            if (data) {
                setUser(data);
            }
            else {
                sendError(error);
            }
        }
        getData();
    },[user_id])

    const handleEdit = (e:any) => {
        setUser({
            ...user,
            [e.target.name]: e.target.value
        });
    }
    const handleTemplateEdit = (e:any) => {
        setUser({
            ...user,
            templates: {
                ...user.templates,
                [e.target.name]: e.target.value
            }
        });
        
    }
    const handleDropdown = (e:any) => {
        setReload(true)
        setUser({
            ...user,
            [e.target.name]: e.target.value
        });
    }
    const save = async (e:any) => {
        e.preventDefault()
        // console.log(user);
        // return;
        const {data, error} = await supabase.from('users').update({
            name: user.name,
            street: user.street,
            streetnumber: user.streetnumber,
            zip: user.zip,
            city: user.city,
            country: user.country,
            bank: user.bank,
            bankaccount: user.bankaccount,
            tax_id: user.tax_id,
            ust_id: user.ust_id,
            lang: user.lang,
            // template_reminder: user.template_reminder,
            templates:user.templates,
            currency: user.currency
        }).eq('id', user_id).select();
        if (data) {
            sendNotify(t('saved'))
            if (reload) {
                navigate(0)
            }
        }
        else {
            sendError(error);
        }
    }

    return (
        <Layout small>
            <SetPassword />
            <div className='flex flex-col items-center p-3'>
                <ToastContainer />
                <h1 className="text-3xl md:text-4xl font-extrabold">{t('ownSettings')}</h1>
                <form className="w-full max-w-3xls" onSubmit={save}>
                    <div className='flex flex-col gap-2'>
                        <div className='flex flex-col md:flex-row md:gap-2'>
                            <label className="form-control w-full md:w-6/12">
                                <div className="label"><span className="label-text">{t('name')}</span></div>
                                <input type="text" placeholder={t('name')} className="input input-sm input-bordered w-full max-w-xss" name='name' value={user.name} onChange={handleEdit} />
                            </label>
                            <label className="form-control w-full md:w-6/12">
                                <div className="label"><span className="label-text">{t('email')}</span></div>
                                <input type="text" placeholder={t('email')} className="input input-sm input-bordered w-full max-w-xss" name='email' value={user.email} onChange={handleEdit} disabled/>
                            </label>
                        </div>
                        <label className="form-control w-full">
                            <div className="label"><span className="label-text">{t('lang')}</span></div>
                            <select className="select select-sm select-bordered" name='lang' onChange={(name:any) => handleDropdown(name)} value={user.lang}>
                                <option value={'de'}>{t('german')}</option>
                                <option value={'en'}>{t('english')}</option>
                            </select>
                        </label>
                        <div className="divider font-bold">{t('address')}</div>
                        <div className='flex flex-col md:flex-row md:gap-2'>
                            <label className="form-control w-full md:w-6/12">
                                <div className="label"><span className="label-text">{t('street')}</span></div>
                                <input type="text" placeholder={t('street')} className="input input-sm input-bordered w-full max-w-xss" name='street' value={user.street} onChange={handleEdit} />
                            </label>
                            <label className="form-control w-full md:w-6/12">
                                <div className="label"><span className="label-text">{t('streetNumber')}</span></div>
                                <input type="text" placeholder={t('streetNumber')} className="input input-sm input-bordered w-full" name='streetnumber' value={user.streetnumber} onChange={handleEdit} />
                            </label>
                        </div>
                        <div className='flex flex-col md:flex-row md:gap-2'>
                            <label className="form-control w-full md:w-6/12">
                                <div className="label"><span className="label-text">{t('zip')}</span></div>
                                <input type="text" placeholder={t('zip')} className="input input-sm input-bordered w-full max-w-xss" name='zip' value={user.zip} onChange={handleEdit} />
                            </label>
                            <label className="form-control w-full md:w-6/12">
                                <div className="label"><span className="label-text">{t('city')}</span></div>
                                <input type="text" placeholder={t('city')} className="input input-sm input-bordered w-full max-w-xss" name='city' value={user.city} onChange={handleEdit} />
                            </label>
                        </div>
                        <label className="form-control w-full">
                            <div className="label"><span className="label-text">{t('country')}</span></div>
                            <input type="text" placeholder={t('country')} className="input input-sm input-bordered w-full max-w-xss" name='country' value={user.country} onChange={handleEdit} />
                        </label>
                        <div className="divider font-bold">{t('invoice')}</div>
                        <label className="form-control w-full">
                            <div className="label"><span className="label-text">{t('bankaccount')}</span></div>
                            <input type="text" placeholder={t('bankaccount')} className="input input-sm input-bordered w-full max-w-xss" name='bankaccount' value={user.bankaccount} onChange={handleEdit} />
                        </label>
                        <label className="form-control w-full">
                            <div className="label"><span className="label-text">{t('bank')}</span></div>
                            <input type="text" placeholder={t('bank')} className="input input-sm input-bordered w-full max-w-xss" name='bank' value={user.bank} onChange={handleEdit} />
                        </label>
                        <label className="form-control w-full">
                            <div className="label"><span className="label-text">{t('currency')}</span></div>
                            <input type="text" placeholder={t('currency')} className="input input-sm input-bordered w-full max-w-xss" name='currency' value={user.currency} onChange={handleEdit} />
                        </label>
                        <label className="form-control w-full">
                            <div className="label"><span className="label-text">{t('taxNumber')}</span></div>
                            <input type="text" placeholder={t('taxNumber')} className="input input-sm input-bordered w-full max-w-xss" name='tax_id' value={user.tax_id} onChange={handleEdit} />
                        </label>
                        <label className="form-control w-full">
                            <div className="label"><span className="label-text">{t('taxId')}</span></div>
                            <input type="text" placeholder={t('taxId')} className="input input-sm input-bordered w-full max-w-xss" name='ust_id' value={user.ust_id} onChange={handleEdit} />
                        </label>
                        <div className="divider font-bold" id="templates">{t('templateTexts')}</div>
                        <label className="form-control w-full">
                            <div className="label"><span className="label-text">{t('endingInvoiceText')}</span></div>
                            <input type="text" placeholder={t('endingInvoiceText')} className="input input-sm input-bordered w-full max-w-xss" name='endingInvoiceText' value={(user.templates?.endingInvoiceText)?user.templates.endingInvoiceText:''} onChange={handleTemplateEdit} />
                        </label>
                        <label className="form-control h-max">
                            <div className="label"><span className="label-text">{t('paymentReminder')}</span></div>
                            <textarea className="textarea textarea-sm textarea-bordered h-64" placeholder={t('paymentReminder')} name='reminder' value={(user.templates?.reminder)?user.templates.reminder:''} onChange={handleTemplateEdit}>{(user.templates?.reminder)?user.templates.reminder:''}</textarea>
                        </label>
                    </div>
                    <div className='mt-5'>
                        <button className='btn btn-primary' type="submit"><Save />{t('save')}</button>
                    </div>
                </form>
            </div>
        </Layout>
    )
}
