import React, { createContext, useEffect, useState } from 'react';
import Dashboard from './Dashboard/Dashboard';
import { Navigate, Route, Routes } from 'react-router-dom';
import Clients from './Pages/(private)/Clients';
import Loading from './components/Loading';
import ClientDetails from './Pages/(private)/ClientDetails';
import ClientEdit from './Pages/(private)/ClientEdit';
import Settings from './Pages/(private)/Settings';
import Invoices from './Pages/(private)/Invoices';
import InvoicesDetails from './Pages/(private)/InvoicesDetails';
import InvoiceNew from './Pages/(private)/InvoiceNew';
import ClientNew from './Pages/(private)/ClientNew';
import InvoiceEdit from './Pages/(private)/InvoiceEdit';
import { useTranslation } from 'react-i18next';
import './utils/i18n';
import 'react-toastify/dist/ReactToastify.css';
import supabase from './utils/supabase';
import Header from './components/Header';
import LandingPage from './Pages/(public)/LandingPage/LandingPage';
import Imprint from './Pages/(public)/imprint/Imprint';
import TOS from './Pages/(public)/tos/tos';
import Privacy from './Pages/(public)/privacy/Privacy';
import Expenses from './Pages/(private)/Expenses';

export const userContext = createContext<any>(false);

function App() {
    const { i18n } = useTranslation();
    const [login, setLogin] = useState<string>('loading');
    const [userLang, setUserLang] = useState(null)
    const [isPremium, setIsPremium] = useState(false)
    useEffect(() => {
        if (userLang!==null) i18n.changeLanguage(userLang)
        else i18n.changeLanguage(navigator.language)
    }, [i18n,userLang])
    useEffect(()=>{
        const getUserData=async () =>{
            const {data} = await supabase.from('users').select('id,lang,has_access').single()
            if (data) {
                setUserLang(data.lang);
                setIsPremium(true)
                // setUserId(data.id)
            }
        }
        supabase.auth.onAuthStateChange((event, session) => {
            if (session) {
                getUserData()
            }
        })
    },[])
    return (
        <userContext.Provider value={[login, setLogin, isPremium]}>
            <Header />
            {(login==='loading')?
            <Loading/>
            :
            <div className="">
                <Routes>
                    {/* <Route path='/' element={<LandingPage loggedin={true} />} /> */}
                    {(login) && 
                    <>
                    <Route path='/home' element={<Dashboard />} />
                    <Route path='/clients' element={<Clients />} />
                    <Route path='/clients/new' element={<ClientNew user_id={login} />} />
                    <Route path='/clients/:id' element={<ClientDetails />} />
                    <Route path='/clients/:id/edit' element={<ClientEdit />} />
                    <Route path='/invoices' element={<Invoices />} />
                    <Route path='/invoices/new/' element={<InvoiceNew user_id={login} />} />
                    <Route path='/invoices/:id' element={<InvoicesDetails />} />
                    <Route path='/invoices/:id/edit' element={<InvoiceEdit />} />
                    <Route path='/expenses' element={<Expenses user_id={login} />} />
                    <Route path='/settings' element={<Settings user_id={login} />} />
                    </>
                    }
                    <Route path='/' element={<LandingPage />} />
                    <Route path='/imprint' element={<Imprint />} />
                    <Route path='/tos' element={<TOS />} />
                    <Route path='/privacy-policy' element={<Privacy />} />
                    <Route path="*" element={<Navigate to="/" />} />
                    <Route path="*" element={<Navigate to="/" />} />
                </Routes>
            </div>
            }
        </userContext.Provider>
    );
}

export default App;
