// @ts-ignore
import { HashLink as Link } from 'react-router-hash-link';
// @ts-ignore
import image from "./img/image_clients.PNG";
import { useTranslation } from 'react-i18next';
import { userContext } from '../../../App';
import { useContext } from 'react';

const Hero = () => {
    const { t } = useTranslation();
    const [login] = useContext(userContext);
    return (
        <section className="max-w-7xl mx-auto bg-base-100 flex flex-col lg:flex-row items-center justify-center gap-16 lg:gap-20 px-8 py-8 lg:py-20">
            <div className="flex flex-col gap-10 lg:gap-14 items-center justify-center text-center lg:text-left lg:items-start">
                <h1 className="font-extrabold text-4xl lg:text-6xl tracking-tight md:-mb-4">CraftBills</h1>
                {/* <p className="text-lg opacity-80 leading-relaxed">The fast way to create invoices</p> */}
                {login?
                <Link to="/home" className="btn btn-primary btn-wide">{t('startNow')}</Link>
                :
                <Link to="#start" className="btn btn-primary btn-wide">{t('startNow')}</Link>
            }
            </div>
            <div className="lg:w-full">
                <img src={image} alt="Product Demo" className="w-full" width={500} height={500} />
            </div>
        </section>
    );
}; 

export default Hero;
