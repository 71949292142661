import React, { useEffect, useState } from 'react'
import supabase from '../../utils/supabase';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Loading from '../../components/Loading';
import PdfGenerator from '../../components/PdfGenerator';
import Modal from '../../components/Modal';
import { useTranslation } from 'react-i18next';
import { allToFixed, getTotal, sendError } from '../../utils/functions';
import InvoiceReminder from '../../components/InvoiceReminder';
import StatusIndicator from '../../components/StatusIndicator';
import { Check, DollarSign, Pencil, Send, Trash, TriangleAlert, XCircle } from 'lucide-react';
import Layout from '../../components/Layout';
import StatusProgressBar from '../../components/StatusProgressBar';
import LinkPath from '../../components/LinkPath';
// import PublicInvoice from '../components/PublicInvoice';

export default function InvoicesDetails() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [status, setStatus] = useState(0);
    // const [total, setTotal] = useState<any>();
    const [modalFinalOpen, setModalFinalOpen] = useState(false);
    const [modalSentOpen, setModalSentOpen] = useState(false);
    const [modalPaidOpen, setModalPaidOpen] = useState(false);
    const [modalRejectOpen, setModalRejectOpen] = useState(false);
    const [modalDeleteOpen, setModalDeleteOpen] = useState(false);
    const [invoice, setInvoice] = useState<Invoices>();
    const {id:invoice_id} = useParams()

    useEffect(()=>{
        const getInvoice = async () => {
            const {data,error} = await supabase.from('invoices').select('*, users(*), clients(*)').eq('id', invoice_id).single();
            if (data) {
                if (data.final_details) {
                    setInvoice(data.final_details);
                }
                else {
                    setInvoice(data);
                }
                setStatus(data.status)
            }
            else {
                sendError(error);
            }
            setLoaded(true);
        }
        getInvoice()
    },[invoice_id])

    const calcTotal = () => {
        let totalSum = 0;
        for (const item of invoice.positions) {
            totalSum += item.price * item.amount;
        }
        const tax = invoice.tax_percent * totalSum / 100
        totalSum +=tax;        
        return totalSum;
    }

    const changeStatus = async (statusVal:number) => {
        if (statusVal===10) {
            const {data,error} = await supabase.from('invoices').update({
                status:statusVal,
                final_details:invoice,
                total:calcTotal()
            }).eq("id",invoice.id).select().single()
    
            if (data) {
                setInvoice(data.final_details);
                setStatus(data.status)
                setModalFinalOpen(false)
            }
            else {
                sendError(error);
            }

        }
        else {
            const {data,error} = await supabase.from('invoices').update({
                status:statusVal,
            }).eq("id",invoice.id).select().single()
    
            if (data) {
                setStatus(data.status)
                setModalSentOpen(false)
                setModalPaidOpen(false)
                setModalRejectOpen(false)
                setModalDeleteOpen(false)
                if (statusVal===100) {
                    navigate('/invoices');
                }
            }
            else {
                sendError(error);
            }
        }
    }

    return loaded ? (
        invoice ?
        <Layout>
            <LinkPath path={[{'name':'invoices','link':'invoices'},{'name':invoice.description,'link':'invoices'}]}/>
            {status>100&&<StatusProgressBar status={status} />}
            <div className="flex justify-between mb-3">
                <h1 className="text-3xl md:text-4xl font-extrabold flex items-center gap-2">{invoice.description} <StatusIndicator status={status}/></h1>
                {status<10 && <Link to={'/invoices/'+invoice.id+'/edit'}><Pencil /></Link>}
            </div>
            <div className="text-sm">
                <p className='font-bold underline'>
                    {invoice.client_id ?
                    <Link to={'/clients/'+invoice.client_id}>{invoice.address.name}</Link>
                    :
                    invoice.address.name
                    }
                </p>
                <p>{invoice.address.street} {invoice.address.streetnumber}</p>
                <p>{invoice.address.zip} {invoice.address.city}, {invoice.address.country}</p>
                <p>{invoice.address.taxId}</p>
            </div>
            <div className='flex flex-col md:flex-row w-full gap-1 justify-end'>
                {status!==30 && 
                    <>
                        {status===0 && 
                        <>
                            <button className='btn hover:btn-primary' onClick={()=>setModalFinalOpen(true)}><Check /> {t('makeFinal')}</button>
                            <button className='btn hover:btn-error' onClick={()=>setModalDeleteOpen(true)}><Trash /> {t('makeDelete')}</button>
                        </>
                        }
                        {status===10 && <button className='btn hover:btn-primary' onClick={()=>setModalSentOpen(true)}><Send /> {t('sent')}</button>}
                        {status===20 && 
                        <>
                            <InvoiceReminder invoice={invoice} />
                            <button className='btn hover:btn-success' onClick={()=>setModalPaidOpen(true)}><DollarSign /> {t('paid')}</button>
                            <button className='btn hover:btn-error' onClick={()=>setModalRejectOpen(true)}><XCircle /> {t('rejected')}</button>
                        </>
                        }
                    </>
                }
                <PdfGenerator invoice={invoice} status={status}/>
            </div>
            <div className="mt-8 mb-3 overflow-y-auto">
                <table className="table table-zebra">
                    <thead>
                        <tr>
                            <th>{t('invoiceID')}</th>
                            <th className="text-right">{t('amount')}</th>
                            <th className="text-right">{t('price')}</th>
                            <th className="text-right">{t('total')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {invoice.positions?.map((position:any) =>{
                            return <tr key={invoice.id+'_'+position.description} className="hover">
                                <td>{position.description}</td>
                                <td className='text-right'>{allToFixed(position.amount)}</td>
                                <td className='text-right'>{allToFixed(position.price)}</td>
                                <td className='text-right'>{allToFixed(position.amount*position.price)}</td>
                            </tr>
                        })}
                        <tr className="hover">
                            <td></td>
                            <td></td>
                            <td className='text-right'>{t('subTotal')}:</td>
                            <td className='text-right'>{allToFixed(getTotal(invoice))}</td>
                        </tr>
                        <tr className="hover">
                            <td></td>
                            <td></td>
                            <td className='text-right'>{t('tax')}:</td>
                            <td className='text-right'>{invoice.tax_percent} %</td>
                        </tr>
                        <tr className="hover">
                            <td></td>
                            <td></td>
                            <td className='text-right'>{t('total')}:</td>
                            <td className='text-right'>{allToFixed(getTotal(invoice)+(getTotal(invoice)*(invoice.tax_percent/100)))}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <Modal isModalOpen={modalFinalOpen} setIsModalOpen={setModalFinalOpen} title={t('makeFinal')}>
                <div className='text-center'>
                    <div className='overflow-auto max-h-[calc(100vh-200px)] flex flex-col items-center gap-5 w-full my-16'>
                        <TriangleAlert />
                        <p className='text-ssm'>{t('makeFinalMsg')}</p>
                    </div>
                    <div className='flex justify-around mt-4'>
                        <button className='btn btn-primary w-1/3' onClick={()=>changeStatus(10)}>{t('continue')}</button>
                        <button className='btn btn-outline btn-error w-1/3' onClick={()=>setModalFinalOpen(false)}>{t('close')}</button>
                    </div>
                </div>
            </Modal>
            <Modal isModalOpen={modalSentOpen} setIsModalOpen={setModalSentOpen} title={t('makeSent')}>
                <div className='text-center'>
                    <div className='overflow-auto max-h-[calc(100vh-200px)] flex flex-col items-center gap-5 w-full my-16'>
                        <TriangleAlert />
                        <p className='text-ssm'>{t('makeSentMsg')}</p>
                    </div>
                    <div className='flex justify-around mt-4'>
                        <button className='btn btn-primary w-1/3' onClick={()=>changeStatus(20)}>{t('continue')}</button>
                        <button className='btn btn-outline btn-error w-1/3' onClick={()=>setModalSentOpen(false)}>{t('close')}</button>
                    </div>
                </div>
            </Modal>
            <Modal isModalOpen={modalPaidOpen} setIsModalOpen={setModalPaidOpen} title={t('makePaid')}>
                <div className='text-center'>
                    <div className='overflow-auto max-h-[calc(100vh-200px)] flex flex-col items-center gap-5 w-full my-16'>
                        <TriangleAlert />
                        <p className='text-ssm'>{t('makePaidMsg')}</p>
                    </div>
                    <div className='flex justify-around mt-4'>
                        <button className='btn btn-primary w-1/3' onClick={()=>changeStatus(30)}>{t('continue')}</button>
                        <button className='btn btn-outline btn-error w-1/3' onClick={()=>setModalPaidOpen(false)}>{t('close')}</button>
                    </div>
                </div>
            </Modal>
            <Modal isModalOpen={modalRejectOpen} setIsModalOpen={setModalRejectOpen} title={t('makeReject')}>
                <div className='text-center'>
                    <div className='overflow-auto max-h-[calc(100vh-200px)] flex flex-col items-center gap-5 w-full my-16'>
                        <TriangleAlert />
                        <p className='text-ssm'>{t('makeRejectMsg')}</p>
                    </div>
                    <div className='flex justify-around mt-4'>
                        <button className='btn btn-primary w-1/3' onClick={()=>changeStatus(40)}>{t('continue')}</button>
                        <button className='btn btn-outline btn-error w-1/3' onClick={()=>setModalRejectOpen(false)}>{t('close')}</button>
                    </div>
                </div>
            </Modal>
            <Modal isModalOpen={modalDeleteOpen} setIsModalOpen={setModalDeleteOpen} title={t('makeDelete')}>
                <div className='text-center'>
                    <div className='overflow-auto max-h-[calc(100vh-200px)] flex flex-col items-center gap-5 w-full my-16'>
                        <TriangleAlert />
                        <p className='text-ssm'>{t('makeDeleteMsg')}</p>
                    </div>
                    <div className='flex justify-around mt-4'>
                        <button className='btn btn-primary w-1/3' onClick={()=>changeStatus(100)}>{t('continue')}</button>
                        <button className='btn btn-outline btn-error w-1/3' onClick={()=>setModalDeleteOpen(false)}>{t('close')}</button>
                    </div>
                </div>
            </Modal>
        </Layout>
        :
        <div>Invoice not found</div>
    ) : (
        <Loading />
    )
}
