import React, { useEffect, useState } from 'react'
import Layout from '../../components/Layout'
import LinkPath from '../../components/LinkPath'
import { useTranslation } from 'react-i18next';
import { Pen, Plus } from 'lucide-react';
import supabase from '../../utils/supabase';
import { getDate, sendError } from '../../utils/functions';
import Loading from '../../components/Loading';
import Modal from '../../components/Modal';


export default function Expenses({user_id}:{user_id:string}) {
    const { t } = useTranslation();
    const today = new Date()    
    const [loaded, setLoaded] = useState(false);
    const [expenses, setExpenses] = useState<Expenses[]>();
    const [deleteExpense, setDeleteExpense] = useState(false);
    const [editExpense, setEditExpense] = useState<Expenses>({})
    const [newExpense, setNewExpense] = useState<Expenses>({
        date:getDate(Date.parse(today.toString())),
        description:'',
        amount:0,
        note:''
    });
    const [modalNewOpen, setModalNewOpen] = useState(false);
    const [modalEditOpen, setModalEditOpen] = useState(false);

    useEffect(()=>{
        const getExpenses = async () => {
            const {data,error} = await supabase.from('expenses').select('*').order('date', { ascending: false });
            if (data) {
                setExpenses(data);
            }
            else {
                sendError(error);
            }
            setLoaded(true);
        }
        getExpenses()
    },[])

    const handleDeleteChange = (e:any) => {
        console.log(e.target.checked);
        setDeleteExpense(e.target.checked);
    }
    const handleNewExpenseChange = (e:any) => {
        setNewExpense({
            ...newExpense,
            [e.target.name]: e.target.value
        });
    }
    const loadExpense = (id:string) => {
        const tmpExpense = expenses?.find((expense:any) => expense.id===id)
        // console.log(test);
        setEditExpense(tmpExpense);
        setModalEditOpen(true)
    }
    const handleEditExpenseChange = (e:any) => {
        setEditExpense({
            ...editExpense,
            [e.target.name]: e.target.value
        });
    }
    const handleEditExpense = async () => {
        const {data, error} = await supabase.from('expenses').update({
            date:editExpense.date,
            description:editExpense.description,
            amount:editExpense.amount,
            note:editExpense.note,
            del:deleteExpense
        }).eq('id', editExpense.id).select()
        // console.log(data, error);
        if (data) {
            // Update expenses
            setExpenses((prevItems:any) =>
                prevItems.map((item:any) =>
                    item['id'] === editExpense.id ? { ...item, ...{date:editExpense.date,description:editExpense.description,amount:editExpense.amount,note:editExpense.note,del:deleteExpense }} : item
                )
            );
            setModalEditOpen(false)
        }
        else {
            sendError(error);
        }
    }
    const handleNewExpense = async () => {
        // amount:allToFixed(Number(newExpense.amount)),
        const {data, error} = await supabase.from('expenses').insert({
            user_id:user_id,
            date:newExpense.date,
            description:newExpense.description,
            amount:newExpense.amount,
            note:newExpense.note
        }).select().single();
        console.log(data, error);
        setModalNewOpen(false)
    }

    return loaded ? (
        <Layout>
            <LinkPath path={[{'name':'expenses','link':'expenses'}]}/>
            <div className='flex justify-between'>
                <h1 className="text-3xl md:text-4xl font-extrabold">{t('expenses')}</h1>
                <button className='btn btn-sm btn-primary btn-circle' onClick={()=>setModalNewOpen(true)}><Plus /></button>
            </div>
            <div className='overflow-y-auto'>
                <table className="table table-zebra">
                    <thead>
                        <tr>
                            <th></th>
                            <th>{t('date')}</th>
                            <th>{t('description')}</th>
                            <th>{t('price')}</th>
                            <th>{t('note')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {expenses?.map((expense:any) =>{
                            if (expense.del===true) return;
                            return <tr key={expense.id} className="hover">
                                <td><button className='btn btn-xs btn-primary' onClick={()=>loadExpense(expense.id)}><Pen size={16} /></button></td>
                                <td>{expense.date}</td>
                                <td>{expense.description}</td>
                                <td>{expense.amount}</td>
                                <td>{expense.note}</td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
            <Modal isModalOpen={modalEditOpen} setIsModalOpen={setModalEditOpen} title={t('editExpense')}>
                <div className='text-center'>
                    <div className='overflow-auto max-h-[calc(100vh-200px)] flex flex-col items-center w-full my-6'>
                        {/* date */}
                        <label className="form-control w-full">
                            <div className="label"><span className="label-text">{t('date')}</span></div>
                            <input type="date" placeholder={t('date')} className="input input-bordered w-full input-sm" name='date' value={editExpense.date} onChange={handleEditExpenseChange} />
                        </label>
                        {/* description */}
                        <label className="form-control w-full">
                            <div className="label"><span className="label-text">{t('description')}</span></div>
                            <input type="text" placeholder={t('description')} className="input input-bordered w-full input-sm" name='description' value={editExpense.description} onChange={handleEditExpenseChange} />
                        </label>
                        {/* price */}
                        <label className="form-control w-full">
                            <div className="label"><span className="label-text">{t('price')}</span></div>
                            <input type="number" placeholder={t('price')} className="input input-bordered w-full input-sm" name='amount' value={editExpense.amount} onChange={handleEditExpenseChange} />
                        </label>
                        {/* note */}
                        <label className="form-control w-full">
                            <div className="label"><span className="label-text">{t('note')}</span></div>
                            <input type="text" placeholder={t('note')} className="input input-bordered w-full input-sm" name='note' value={editExpense.note} onChange={handleEditExpenseChange} />
                        </label>
                        <div className="form-control w-full">
                            <label className="label cursor-pointer w-44">
                                <span className="label-text">{t('delete')}</span>
                                <input type="checkbox" className="toggle toggle-error" onChange={handleDeleteChange}/>
                            </label>
                        </div>
                    </div>
                    <div className='flex justify-around mt-4'>
                        <button className='btn btn-primary w-1/3' onClick={()=>handleEditExpense()}>{t('save')}</button>
                        <button className='btn btn-outline btn-error w-1/3' onClick={()=>setModalEditOpen(false)}>{t('close')}</button>
                    </div>
                </div>
            </Modal>
            <Modal isModalOpen={modalNewOpen} setIsModalOpen={setModalNewOpen} title={t('newExpense')}>
                <div className='text-center'>
                    <div className='overflow-auto max-h-[calc(100vh-200px)] flex flex-col items-center w-full my-6'>
                        {/* date */}
                        <label className="form-control w-full">
                            <div className="label"><span className="label-text">{t('date')}</span></div>
                            <input type="date" placeholder={t('date')} className="input input-bordered w-full input-sm" name='date' value={newExpense.date} onChange={handleNewExpenseChange} />
                        </label>
                        {/* description */}
                        <label className="form-control w-full">
                            <div className="label"><span className="label-text">{t('description')}</span></div>
                            <input type="text" placeholder={t('description')} className="input input-bordered w-full input-sm" name='description' value={newExpense.description} onChange={handleNewExpenseChange} />
                        </label>
                        {/* price */}
                        <label className="form-control w-full">
                            <div className="label"><span className="label-text">{t('price')}</span></div>
                            <input type="number" placeholder={t('price')} className="input input-bordered w-full input-sm" name='amount' value={newExpense.amount} onChange={handleNewExpenseChange} />
                        </label>
                        {/* note */}
                        <label className="form-control w-full">
                            <div className="label"><span className="label-text">{t('note')}</span></div>
                            <input type="text" placeholder={t('note')} className="input input-bordered w-full input-sm" name='note' value={newExpense.note} onChange={handleNewExpenseChange} />
                        </label>
                    </div>
                    <div className='flex justify-around mt-4'>
                        <button className='btn btn-primary w-1/3' onClick={()=>handleNewExpense()}>{t('save')}</button>
                        <button className='btn btn-outline btn-error w-1/3' onClick={()=>setModalNewOpen(false)}>{t('close')}</button>
                    </div>
                </div>
            </Modal>
        </Layout>
    ) : (
        <Loading />
    )
}
