import React, { useEffect, useState } from 'react'
import supabase from '../utils/supabase';
import { allToFixed, sendError } from '../utils/functions';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export default function Analytics() {
    const { t } = useTranslation();
    const [paidTotal, setPaidTotal] = useState(0);
    const [paid30, setPaid30] = useState(0);
    const [upcoming, setUpcoming] = useState(0);

    useEffect(()=>{
        const getData = async () => {
            const {data, error} = await supabase.from('invoices').select('total, status, created_at').gt('total',0).neq('status',40);
            if (data?.length) {
                const total = data.reduce((accumulator:any, currentValue:any)=>{
                    if (!accumulator.total) accumulator.total = 0;
                    if (!accumulator.current) accumulator.current = 0;
                    if (!accumulator.open) accumulator.open = 0;

                    if (currentValue.status===10||currentValue.status===20) {
                        accumulator.open+=currentValue.total
                    }
                    else if (currentValue.status===100) {}
                    else if (currentValue.created_at>=new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).toISOString()) {
                        accumulator.total+=currentValue.total
                        accumulator.current+=currentValue.total
                    }
                    else accumulator.total+=currentValue.total
                        
                    return accumulator
                },{})
                setPaidTotal(total.total);
                setPaid30(total.current);
                setUpcoming(total.open);
                
            }
            else {
                sendError(error);
            }
        }
        getData();
    },[])

    return (
        <div className="stats bg-base-300 text-primary-contents stats-vertical md:stats-horizontal w-full max-h-40s">
            <div className="stat">
                <div className="stat-title">{t('income')}</div>
                <div className="stat-value">{allToFixed(paidTotal)}</div>
                <div className="stat-desc">{t('last30')} ↗︎ {allToFixed(paid30)}</div>
                <div className="stat-actions">
                    <Link to={'/invoices/new'} className="btn btn-sm btn-success">{t('newInvoice')}</Link>
                </div>
            </div>

            <div className="stat flex flex-col">
                <div className="stat-title">{t('open')}</div>
                <div className="stat-value">{allToFixed(upcoming)}</div>
            </div>
        </div>
    )
}
