import { useTranslation } from "react-i18next";
import Login from "../../../components/Login";

const LoginStart = () => {
    const { t } = useTranslation();

    return (
        <section className="bg-base-100 mt-20 mb-12 md:mb-24 md:mt-24" id="start">
            <div className="w-full flex flex-col items-center py-3">
                <h2 className="font-extrabold text-4xl lg:text-6xl tracking-tight mb-20 md:mb-24">{t('register')}</h2>
                <Login bigBtn />
            </div>
        </section>
    );
};

export default LoginStart;