import { useTranslation } from 'react-i18next';

export default function StatusProgressBar({status}:{status:number}) {
    const { t } = useTranslation();
    return (
        <ul className="steps steps-horizontal w-full text-xs mb-4">
            {/* <li className={"step step-primary"} data-content={"🖊️"}>{t('draft')}</li> */}
            <li className={"step"+(status===40?' step-error':(status===30?' step-success':' step-primary'))}>{t('draft')}</li>
            <li className={"step"+(status===40?' step-error':(status===30?' step-success':(status>=10?' step-primary':'')))}>{t('created')}</li>
            <li className={"step"+(status===40?' step-error':(status===30?' step-success':(status>=20?' step-primary':'')))}>{t('sent')}</li>
            <li className={"step"+(status===40?' step-error':(status===30?' step-success':''))}>{t('paid')}</li>
        </ul>
    )
}
