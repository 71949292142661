import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import supabase from '../../utils/supabase'
import Loading from '../../components/Loading';
import { v4 } from "uuid";
import InvoiceEditNew from '../../components/InvoiceEditNew';
import { useTranslation } from 'react-i18next';
import { sendError } from '../../utils/functions';
import Layout from '../../components/Layout';
import LinkPath from '../../components/LinkPath';

export default function InvoiceNew({user_id}:{user_id:string}) {
    const { t } = useTranslation();
    const navigate = useNavigate()
    const [searchParams] = useSearchParams();
    const [loaded, setLoaded] = useState(false);
    const [clients, setClients] = useState<Clients[]>();
    const [client, setClient] = useState<Clients>();
    const [address, setAddress] = useState({});
    const [positions, setPositions] = useState<any[]>([]);
    const [endingInvoiceTextTemplate, setEndingInvoiceTextTemplate] = useState<string>('');
    const [invoice, setInvoice] = useState<Invoices>({
        description: '',
        tax_percent: 0,
        payment_days: 30,
    });

    useEffect(()=>{;
        const getData = async () => {
            const {data, error} = await supabase.from('users').select('templates, clients(id,name,street,streetnumber,zip,city,country,email,phone,ust_id)').single()
            if (data) {
                setClients(data.clients);
                if (data.templates?.endingInvoiceText) { setEndingInvoiceTextTemplate(data.templates.endingInvoiceText); }
            }
            else {
                sendError(error);
            }
            setLoaded(true);
        }
        getData();
    },[])

    useEffect(()=>{;
        const tmpClient = clients?.find( oneclient => searchParams.get('client') === oneclient.id )
        
        if (tmpClient) {
            setClient(tmpClient)
            setAddress({
                name:tmpClient.name,
                street:tmpClient.street,
                streetnumber:tmpClient.streetnumber,
                zip:tmpClient.zip,
                city:tmpClient.city,
                country:tmpClient.country,
                email:tmpClient.email,
                taxId:tmpClient.ust_id
            })
        }
    },[clients,searchParams])

    const handleEdit = (e:any) => {
        setInvoice({
            ...invoice,
            [e.target.name]: e.target.value
        });
    }
    const handleCheck = (e:any) => {
        // console.log(e.target.name, e.target.checked)
        setInvoice({
            ...invoice,
            [e.target.name]: e.target.checked
        });
    }
    const handleDropdown = (e:any) => {
        setInvoice({
            ...invoice,
            [e.target.name]: e.target.value
        });
    }
    const handlePositionDescriptionChange = (id: string, newText: string) => {
        setPositions((prevPos:any[]) =>
            prevPos.map(pos =>
                pos.id === id ? { ...pos, description: newText } : pos
            )
        );
    }
    const handlePositionAmountChange = (id: string, newAmount: string) => {
        setPositions((prevPos:any[]) =>
            prevPos.map(pos =>
                pos.id === id ? { ...pos, amount: Number(newAmount) } : pos
            )
        );
    }
    const handlePositionPriceChange = (id: string, newPrice: string) => {
        setPositions((prevPos:any[]) =>
            prevPos.map(pos =>
                pos.id === id ? { ...pos, price: Number(newPrice) } : pos
            )
        );
    }
    const handleAddNewPosition = () => {
        const newId = v4()
        setPositions((prevPos:any[]) => [...prevPos, { id: newId, description: '', amount: 1, price: '' }]);
    };
    const handleDeletePosition = (id: string) => {
        setPositions((prevPos:any[]) => prevPos.filter(link => link.id !== id));
    };

    const save = async (e:any) => {
        e.preventDefault()
        const {data, error} = await supabase.from('invoices').insert({
            ...invoice,
            tax_percent:Number(invoice.tax_percent),
            user_id:user_id,
            client_id:(client?.id?client?.id:null),
            positions:positions,
            extra_text:invoice.extra_text,
            address:address
        }).select().single();
        if (data) {
            navigate('/invoices/'+data.id)
        }
        else {
            sendError(error);
        }
    }

    const handleClientChange = (e:any) => {
        const tmpClient = clients?.find( oneclient => e.target.value === oneclient.id )
        setClient(tmpClient)
        
        if (tmpClient) {
            setAddress({
                name:tmpClient.name,
                street:tmpClient.street,
                streetnumber:tmpClient.streetnumber,
                zip:tmpClient.zip,
                city:tmpClient.city,
                country:tmpClient.country,
                taxId:tmpClient.ust_id
            })
        }
        
    }
    const handleAddressChange = (e:any) => {
        setAddress({
            ...address,
            [e.target.name]: e.target.value
        });
    }

    return loaded ? (
        <Layout>
            <LinkPath path={[{'name':'invoices','link':'invoices'},{'name':'new','link':'clients'}]}/>
            {/* TODO: */}
            <h1 className="text-3xl md:text-4xl font-extrabold">{t('newInvoice')}</h1>
            <div className='w-full max-w-3xl'>
                {clients?.length &&
                <label className="form-control w-full max-w-xss">
                    <div className="label">
                        <span className="label-text">{t('pickCustomer')}</span>
                    </div>
                    <select className="select select-bordered" onChange={handleClientChange}>
                        <option disabled selected>{t('pickCustomer')}</option>
                        {clients?.map((oneclient) =>{
                            return <option selected={searchParams.get('client')===oneclient.id} key={oneclient.id} value={oneclient.id}>{oneclient.name}</option>
                        })}
                    </select>
                </label>
                }
            </div>
            <InvoiceEditNew clientAddress={client} invoice={invoice} save={save} handleEdit={handleEdit} handleCheck={handleCheck} handleDropdown={handleDropdown} positions={positions} handlePositionDescriptionChange={handlePositionDescriptionChange} handlePositionAmountChange={handlePositionAmountChange} handlePositionPriceChange={handlePositionPriceChange} handleDeletePosition={handleDeletePosition} handleAddNewPosition={handleAddNewPosition} handleAddressChange={handleAddressChange} endingInvoiceText={endingInvoiceTextTemplate}/>
        </Layout>
    ) : (
        <Loading />
    )
}
