import { Plus, Save, Trash } from 'lucide-react';
import React from 'react'
import { useTranslation } from 'react-i18next';

export default function InvoiceEditNew({clientAddress,invoice,save,handleEdit,handleAddressChange,handleCheck,handleDropdown,positions,handlePositionDescriptionChange,handlePositionAmountChange,handlePositionPriceChange,handleDeletePosition,handleAddNewPosition,endingInvoiceText}:{clientAddress?:Clients,invoice:Invoices,save:any,handleEdit:any,handleAddressChange?:any,handleCheck:any,handleDropdown:any,positions:any[],handlePositionDescriptionChange:any,handlePositionAmountChange:any,handlePositionPriceChange:any,handleDeletePosition:any,handleAddNewPosition:any,endingInvoiceText?:string}) {
    const { t } = useTranslation();

    return (
        <form className="w-full max-w-3xls" onSubmit={save}>
            <div className='flex flex-col gap-5'>
                <label className="form-control w-full">
                    <div className="label"><span className="label-text">{t('invoiceID')}</span></div>
                    <input type="text" required placeholder={t('invoiceID')} className="input input-bordered w-full" name='description' value={invoice.description} onChange={handleEdit} />
                </label>
                <div className='mb-5'></div>
                {!invoice.id && 
                    <div className='flex flex-col'>
                        <label className={"form-control w-full"+((clientAddress)?' hidden':'')}>
                            <div className="label"><span className="label-text">{t('name')}</span></div>
                            <input type="text" required placeholder={t('name')} className="input input-bordered w-full" name='name' value={clientAddress?.name} onChange={handleAddressChange} />
                        </label>
                        <label className={"form-control w-full"+((clientAddress)?' hidden':'')}>
                            <div className="label"><span className="label-text">{t('taxId')}</span></div>
                            <input type="text" placeholder={t('taxId')} className="input input-bordered w-full" name='ust_id' value={clientAddress?.ust_id} onChange={handleAddressChange} />
                        </label>
                        <div className='flex flex-col md:flex-row md:gap-2'>
                            <label className={"form-control w-full"+((clientAddress)?' hidden':'')}>
                                <div className="label"><span className="label-text">{t('street')}</span></div>
                                <input type="text" required placeholder={t('street')} className="input input-bordered w-full" name='street' value={clientAddress?.street} onChange={handleAddressChange} />
                            </label>
                            <label className={"form-control w-full"+((clientAddress)?' hidden':'')}>
                                <div className="label"><span className="label-text">{t('streetNumber')}</span></div>
                                <input type="text" required placeholder={t('streetNumber')} className="input input-bordered w-full" name='streetnumber' value={clientAddress?.streetnumber} onChange={handleAddressChange} />
                            </label>
                        </div>
                        <label className={"form-control w-full"+((clientAddress)?' hidden':'')}>
                            <div className="label"><span className="label-text">{t('zip')}</span></div>
                            <input type="text" required placeholder={t('zip')} className="input input-bordered w-full" name='zip' value={clientAddress?.zip} onChange={handleAddressChange} />
                        </label>
                        <label className={"form-control w-full"+((clientAddress)?' hidden':'')}>
                            <div className="label"><span className="label-text">{t('city')}</span></div>
                            <input type="text" required placeholder={t('city')} className="input input-bordered w-full" name='city' value={clientAddress?.city} onChange={handleAddressChange} />
                        </label>
                        <label className={"form-control w-full"+((clientAddress)?' hidden':'')}>
                            <div className="label"><span className="label-text">{t('country')}</span></div>
                            <input type="text" placeholder={t('country')} className="input input-bordered w-full" name='country' value={clientAddress?.country} onChange={handleAddressChange} />
                        </label>
                        <div className='mb-5'></div>
                    </div>
                }
                <div className='flex flex-col md:flex-row md:gap-2'>
                        <label className="form-control w-full">
                            <div className="label">
                                <span className="label-text">{t('taxPercent')}</span>
                            </div>
                            <select className="select select-bordered" name='tax_percent' onChange={(name:any) => handleDropdown(name)} value={invoice.tax_percent}>
                                <option value={0}>0%</option>
                                <option value={7}>7%</option>
                                <option value={16}>16%</option>
                                <option value={19}>19%</option>
                            </select>
                        </label>
                        <label className="form-control w-full">
                            <div className="label"><span className="label-text">{t('paymentDeadline')}</span></div>
                            <input type="number" placeholder={t('paymentDeadline')} className="input input-bordered w-full max-w-xss" name='payment_days' min={0} max={90} value={invoice.payment_days} onChange={handleEdit} />
                        </label>
                    </div>
                <label className="form-control w-full">
                    <div className="label"><span className="label-text">{t('extra_text')}</span></div>
                    <input type="text" placeholder={t('extra_text')} maxLength={300} className="input input-bordered w-full" name='extra_text' value={(endingInvoiceText)?endingInvoiceText:invoice.extra_text} onChange={handleEdit} />
                </label>
                <div className='divider'></div>
                <div className='flex items-center gap-2'>{t('positions')}<button className='btn btn-circle btn-sm' type="button" onClick={handleAddNewPosition}><Plus /></button></div>
                {positions?.map(position => {
                    return <div className='flex flex-col md:flex-row gap-1 w-full mb-3' key={position.id}>
                        <input type="text" placeholder={t('description')} className="input input-bordered w-full md:w-6/12" name='description' value={position.description} onChange={(e) => handlePositionDescriptionChange(position.id, e.target.value)} />
                        <input type="number" placeholder={t('amount')} className="input input-bordered w-full md:w-3/12" name='amount' min={0.1} step={0.1} value={position.amount} onChange={(e) => handlePositionAmountChange(position.id, e.target.value)} />
                        <input type="number" placeholder={t('price')} className="input input-bordered w-full md:w-3/12" name='price' value={position.price} onChange={(e) => handlePositionPriceChange(position.id, e.target.value)} />
                        <button className='btn hover:btn-error' type="button" onClick={()=>handleDeletePosition(position.id)}><Trash /></button>
                    </div>
                })}
                <div className='divider'></div>
            </div>
            <div className='mt-3'>
                <button className='btn btn-primary' type="submit"><Save /></button>
            </div>
        </form>
    )
}
