import React, { useEffect, useState } from 'react'
import supabase from '../../utils/supabase';
import Loading from '../../components/Loading';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { sendError } from '../../utils/functions';
import Layout from '../../components/Layout';
import LinkPath from '../../components/LinkPath';
import { Plus, SquareArrowOutUpRight } from 'lucide-react';
// import { userContext } from '../../App';

export default function Clients() {
    const { t } = useTranslation();
    const [loaded, setLoaded] = useState(false);
    const [clients, setClients] = useState<Clients[]>();
    // const [, , isPremium] = useContext(userContext);
    // const navigate = useNavigate()

    // useEffect(()=>{
    //     if (!isPremium) navigate('/#pricing')
    // },[isPremium,navigate])

    useEffect(()=>{
        const getClient = async () => {
            const {data,error} = await supabase.from('clients').select('*,invoices(total)');
            if (data) {
                setClients(data);
            }
            else {
                sendError(error);
            }
            setLoaded(true);
        }
        getClient()
    },[])

    return loaded ? (
        <Layout>
            <LinkPath path={[{'name':'clients','link':'clients'}]}/>
            <div className='flex justify-between'>
                <h1 className="text-3xl md:text-4xl font-extrabold">{t('clients')}</h1>
                <Link className='btn btn-sm btn-primary btn-circle' to={'/clients/new'}><Plus /></Link>
            </div>
            <div className='overflow-y-auto'>
                <table className="table table-zebra">
                    <thead>
                        <tr>
                            <th></th>
                            <th>{t('name')}</th>
                            <th>{t('phone')}</th>
                            <th>{t('email')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {clients?.map((client:any) =>{
                            return <tr key={client.id} className="hover">
                                <td>
                                    <Link className='btn btn-xs btn-primary' to={"/clients/"+client.id}>
                                        <SquareArrowOutUpRight size={16} />
                                    </Link>
                                </td>
                                <td>{client.name}</td>
                                <td>{client.phone}</td>
                                <td>{client.email}</td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
        </Layout>
    ) : (
        <Loading table={true} />
    )
}
