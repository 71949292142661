import React from 'react'
import { useTranslation } from 'react-i18next';

export default function StatusIndicator({status}:{status:number}) {
    const { t } = useTranslation();

    if (status===40) return <div className="badge badge-error">{t('rejected')}</div>
    else if (status===100) return <div className="badge badge-error">{t('deleted')}</div>
    else if (status===30)return <div className="badge badge-success">{t('paid')}</div>
    else if (status===20)return <div className="badge badge-warning">{t('sent')}</div> //Gesendet fliegt raus
    else if (status===10)return <div className="badge badge-info">{t('created')}</div>
    else return <div className="badge badge-neutral">{t('draft')}</div>

    // if (status===40) return <div className='text-red-900 bg-red-200 rounded-full w-24 flex items-center gap-2 pl-2'><div className='w-2 h-2 bg-red-900 rounded-full'></div>{t('rejected')}</div>
    // else if (status===30) return <div className='text-green-900 bg-green-200 rounded-full w-24 flex items-center gap-2 pl-2'><div className='w-2 h-2 bg-green-900 rounded-full'></div>{t('paid')}</div>
    // else if (status===20) return <div className='text-orange-900 bg-orange-200 rounded-full w-24 flex items-center gap-2 pl-2'><div className='w-2 h-2 bg-orange-900 rounded-full'></div>{t('sent')}</div>
    // else if (status===10) return <div className='text-blue-900 bg-blue-200 rounded-full w-24 flex items-center gap-2 pl-2'><div className='w-2 h-2 bg-blue-900 rounded-full'></div>{t('created')}</div>
    // else return <div className='text-slate-900 bg-slate-200 rounded-full w-24 flex items-center gap-2 pl-2'><div className='w-2 h-2 bg-slate-900 rounded-full'></div>{t('draft')}</div>
}
