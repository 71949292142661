import React, { Fragment, ReactNode } from 'react'
import { Dialog, Transition } from "@headlessui/react";
interface ModalProps {
    isModalOpen: boolean;
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    isStatic?: boolean;
    title?: string;  // Optional title prop
    children?: ReactNode;  // Children elements
}

export default function Modal({ isModalOpen, setIsModalOpen, title = "I'm a modal", isStatic = false, children }: ModalProps) {
    return (
        <Transition appear show={isModalOpen} as={Fragment}>
            <Dialog as="div" className="relative z-50" static={isStatic} onClose={() => isStatic ? null : setIsModalOpen(false)} >
                <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0" >
                    <div className="fixed inset-0 bg-neutral-focus bg-opacity-50" />
                </Transition.Child>
                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full overflow-hidden items-start md:items-center justify-center p-2">
                        <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95" >
                            <Dialog.Panel className="relative w-full max-w-3xl h-full overflow-visible transform text-left align-middle shadow-xl transition-all rounded-xl bg-base-100 p-6 md:p-8">
                                <div className="flex justify-between items-center mb-4">
                                    <Dialog.Title as="h2" className="font-semibold">
                                        {title}
                                    </Dialog.Title>
                                    {isStatic ? null : 
                                        <button className="btn btn-square btn-ghost btn-sm" onClick={() => setIsModalOpen(false)} >
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5" >
                                                <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                                            </svg>
                                        </button>
                                    }
                                </div>
                                {children}
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};