import { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
// import Image from "next/image";

interface Feature {
    title: string;
    description: string;
    type?: "video" | "image";
    path?: string;
    format?: string;
    alt?: string;
}

// An SEO-friendly accordion component including the title and a description (when clicked.)
const Item = ({index, feature, isOpen, setFeatureSelected}: {index: number; feature: Feature; isOpen: boolean; setFeatureSelected: () => void}) => {
    const accordion = useRef<any>(null);
    const { title, description } = feature;

    return (
        <li>
            <button className="relative flex gap-2 items-center w-full py-5 text-base font-medium text-left border-t md:text-lg border-base-content/10" onClick={(e) => { e.preventDefault(); setFeatureSelected(); }} aria-expanded={isOpen} >
                <span className={`flex-1 text-base-content ${isOpen ? "text-primary" : ""}`} >
                    <span className={`mr-2`}>{index + 1}.</span>
                    <h3 className="inline">{title}</h3>
                </span>
                <svg className={`flex-shrink-0 w-4 h-4 ml-auto fill-current ${isOpen ? "fill-primary" : "" }`} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" >
                    <rect y="7" width="16" height="2" rx="1" className={`transform origin-center transition duration-200 ease-out ${isOpen && "rotate-180" }`} />
                    <rect y="7" width="16" height="2" rx="1" className={`transform origin-center rotate-90 transition duration-200 ease-out ${isOpen && "rotate-180 hidden" }`} />
                </svg>
            </button>

            <div ref={accordion} className={`transition-all duration-300 ease-in-out text-base-content-secondary overflow-hidden`} style={ isOpen ? { maxHeight: accordion?.current?.scrollHeight, opacity: 1 } : { maxHeight: 0, opacity: 0 } } >
                <div className="pb-5 leading-relaxed">{description}</div>
            </div>
        </li>
    );
};

// A component to display 2 to 5 features in an accordion.
// By default, the first feature is selected. When a feature is clicked, the others are closed.
const FeaturesAccordion = () => {
    const { t } = useTranslation();
    const [featureSelected, setFeatureSelected] = useState<number>(0);
    const features = [
        {
            title: t('feature1'),
            description: t('feature11'),
        },
        {
            title: t('feature2'),
            description: t('feature21'),
        },
        {
            title: t('feature3'),
            description: t('feature31'),
        },
        {
            title: t('feature4'),
            description: t('feature41'),
        },
    ] as Feature[];
    return (
        <section className="py-24 md:py-32 space-y-24 md:space-y-32 max-w-7xl mx-auto bg-base-100" id="features" >
            <div className="px-8">
                <p className="font-medium text-sm text-accent mb-2">Features</p>
                <h2 className="font-extrabold text-4xl lg:text-6xl tracking-tight mb-12 md:mb-24">
                    {t('yourInvoiceIn')}
                    <span className="bg-neutral text-neutral-content px-2 md:px-4 ml-1 md:ml-1.5 leading-relaxed whitespace-nowrap">{t('withCraftbills')}</span>
                </h2>
                <div className=" flex flex-col md:flex-row gap-12 md:gap-24">
                    <ul className="w-full">
                        {features.map((feature, i) => (
                            <Item key={feature.title} index={i} feature={feature} isOpen={featureSelected === i} setFeatureSelected={() => setFeatureSelected(i)} />
                        ))}
                    </ul>
                </div>
            </div>
        </section>
    );
};

export default FeaturesAccordion;
