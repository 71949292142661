import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import Loading from '../../components/Loading';
import supabase from '../../utils/supabase';
import { useTranslation } from 'react-i18next';
import { allToFixed, sendError } from '../../utils/functions';
import Layout from '../../components/Layout';
import { Pencil, Plus, SquareArrowOutUpRight, TriangleAlert } from 'lucide-react';
import StatusIndicator from '../../components/StatusIndicator';
import LinkPath from '../../components/LinkPath';


export default function ClientDetails() {
    const { t } = useTranslation();
    const [loaded, setLoaded] = useState(false);
    const [client, setClient] = useState<Clients>();
    const [invoices, setInvoices] = useState<Invoices[]>();
    const {id:client_id} = useParams()
    // FIXME: invoice out of customer
    useEffect(()=>{;
        const getData = async () => {
            const {data, error} = await supabase.from('clients').select('*').eq('id', client_id).single();
            if (data) {
                setClient(data);
            }
            else {
                sendError(error);
            }
            await getInvoice();
            setLoaded(true);
        }
        const getInvoice = async () => {
            const {data,error} = await supabase.from('invoices').select('id, description, status, total, created_at, address, users(currency)').eq('client_id',client_id).lt('status',100).order('created_at',{ ascending: false });
            if (data) {
                setInvoices(data);
            }
            else {
                sendError(error);
            }
        }
        getData();
    },[client_id])


    return loaded ? (
        client ?
        <Layout>
            <LinkPath path={[{'name':'clients','link':'clients'},{'name':client.name,'link':'clients'}]}/>
            <div className="flex justify-between mb-3">
                <h1 className="text-3xl md:text-4xl font-extrabold">{client.name}</h1>
                <Link to={'/clients/'+client.id+'/edit'}><Pencil /></Link>
            </div>
            <div className='flex flex-col md:flex-row justify-between'>
                <div>
                    <p className='text-gray-500 text-sm'>{t('phone')}: <Link className='underline' to={'tel:'+client.phone}>{client.phone}</Link></p>
                    <p className='text-gray-500 text-sm'>{t('email')}: <Link className='underline' to={'mailto:'+client.email}>{client.email}</Link></p>
                </div>
                <div>
                    <p className='text-gray-500 text-sm'>{t('address')}: <span>{client.street+' '+client.streetnumber+', '+client.city+', '+client.country}</span></p>
                    <p className='text-gray-500 text-sm'>{t('taxId')}: <span>{client.ust_id}</span></p>
                </div>
            </div>
            {client.info && 
                <div className="flex items-center mt-5">
                    <TriangleAlert />
                    <div className="ml-3">
                        <p className='text-gray-500 text-sm'>{t('clientNotes')}:</p>
                        <p className='text-sm'>{client.info}</p>
                    </div>
                </div>
            }
            <div className="mt-8 mb-3">
                <div className='flex justify-between'>
                    <h1 className="text-3xl md:text-4xl font-extrabold">{t('invoices')}</h1>
                    <Link className='btn btn-sm btn-primary btn-circle' to={'/invoices/new?client='+client.id}><Plus /></Link>
                </div>
                <div className='overflow-y-auto'>
                    <table className="table table-zebra">
                        <thead>
                            <tr>
                                <th></th>
                                <th>{t('invoiceID')}</th>
                                <th>{t('total')}</th>
                                <th>{t('status')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {invoices?.map((invoice:any) =>{
                                return <tr key={invoice.id} className="hover">
                                    <td>
                                        <Link className='btn btn-xs btn-primary' to={"/invoices/"+invoice.id}>
                                            <SquareArrowOutUpRight size={16} />
                                        </Link>
                                    </td>
                                    <td>{invoice.description}</td>
                                    <td>{allToFixed(invoice.total)}</td>
                                    <td><StatusIndicator status={invoice.status}/></td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </Layout>
        :
        <div>{t('clientNotFound')}</div>
    ) : (
        <Loading />
    )
}
