import { ReactNode } from 'react';


const Layout = ({ children,small }: { children: ReactNode,small?:boolean }) => {
    return (
        <div className="flex flex-col items-center">
            <main className={"flex-1 p-2 md:p-8 pt-8 overflow-y-auto_ h-screens w-full max-w-"+(small?"5":"7")+"xl"}>
                {children}
            </main>
        </div>
    );
}

export default Layout;
