import jsPDF from "jspdf";
import autoTable, { CellInput } from "jspdf-autotable";
import { toast } from "react-toastify";
import { imgData } from '../img/imgBase64';
import i18next from 'i18next';

export const sendNotify = (msg:string,error?:boolean) => {
    const options:any= {
        position: "top-left",
        autoClose: 500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "dark",
    };
    if (error) toast.error(msg, options);
    else toast.success(msg, options);
}

export const getTotal = (invoice:Invoices) => {
    let totalSum = 0;
    for (const item of invoice.positions) {
        totalSum += item.price * item.amount;
    }
    return totalSum;
}

export const getDate = (UNIX_timestamp:number) => {
    let yourDate = new Date(UNIX_timestamp)
    var date = yourDate.toISOString().split('T')[0]
    return date;
}

export const generatePDF = (invoice:Invoices,status:number,view:boolean) => {
    // NOTE: bofore making changes, check for invoice.marker_for_pdf
    const doc = new jsPDF();
    const currency=' '+invoice.users.currency
    
    // SECTION: Preview water mark
    if (view&&status<10) {
        doc.setFontSize(50);
        doc.setTextColor(150);
        doc.text('PREVIEW',20, 30,{angle:-45});
        doc.text('PREVIEW',120, 30,{angle:-45});
        doc.setTextColor(0);
    }
    // SECTION: data client and user
    autoTable(doc, {startY: 25,
        theme:'plain',
        head:[[invoice.address.name, invoice.users.name]],
        body: [
            [
                invoice.address.street+' '+invoice.address.streetnumber+'\n'+
                invoice.address.zip+' '+invoice.address.city+'\n'+
                invoice.address.country+'\n'+
                ((invoice.address.taxId)?i18next.t('taxId')+': '+invoice.address.taxId+'\n':'')
                ,
                invoice.users.street+' '+invoice.users.streetnumber+'\n'+
                invoice.users.zip+' '+invoice.users.city+'\n'+
                invoice.users.country+'\n'+
                ((invoice.users.ust_id)?i18next.t('taxId')+': '+invoice.users.ust_id+'\n':'')+
                i18next.t('taxNumber')+': '+invoice.users.tax_id
            ]
          // ...
        ],
        columnStyles: {
            0: { halign: 'left', cellPadding: 0, textColor: 'rgb(75, 85, 99)', cellWidth:125},
            1: { halign: 'left', cellPadding: 0, textColor: 'rgb(75, 85, 99)'},
        },
        headStyles :{
            cellPadding: 0,
            textColor:'rgb(55, 65, 81)'
        }
    })
    // SECTION: invoice id and date
    autoTable(doc, {
        theme:'plain',
        startY: 75,
        head: [[i18next.t('invoice')+':','']],
        body: [
          [i18next.t('invoiceID')+": "+invoice.description, i18next.t('invoiceDate')+': '+getDate(Date.parse(invoice.created_at))],
        ],
        columnStyles: {
            0: { halign: 'left', cellPadding: 0 },
            1: { halign: 'right', cellPadding: 0 },
        },
        headStyles :{
            cellPadding: 0,
            fontSize:16
        }
    })

    // SECTION: create rows for positions
    const arrayPositions:any[][] = [];
    let totalCosts = 0;
    invoice.positions.forEach((position:any,index:number) => {
        const newArr:CellInput = [
            index+1,
            position.description,
            // position.amount.toFixed(2),
            allToFixed(position.amount),
            // position.price.toFixed(2)+currency,
            allToFixed(position.price)+currency,
            // (position.amount*position.price).toFixed(2)+currency
            allToFixed(position.amount*position.price)+currency,
        ]
        arrayPositions.push(newArr);
        totalCosts=totalCosts+(position.amount*position.price);
    });
    const tax=invoice.no_tax?0:invoice.tax_percent;
    const zwischenRow = [
        '',
        '',
        '',
        '\n'+i18next.t('subTotal')+':\n\n'+i18next.t('tax')+' ('+tax+'%):\n\n'+i18next.t('total')+':',
        // '\n'+totalCosts.toFixed(2)+currency+'\n\n'+(totalCosts*(tax/100)).toFixed(2)+currency+'\n\n'+(totalCosts+(totalCosts*(tax/100))).toFixed(2)+currency
        '\n'+allToFixed(totalCosts)+currency+'\n\n'+allToFixed(totalCosts*(tax/100))+currency+'\n\n'+allToFixed(totalCosts+(totalCosts*(tax/100)))+currency
    ]
    arrayPositions.push(zwischenRow);
    
    // SECTION: positions table
    autoTable(doc, {
        startY: 90,
        head: [['Pos', i18next.t('description'), i18next.t('amount'), i18next.t('price'), i18next.t('total')]],
        body: arrayPositions,
        columnStyles: {
            0: { halign: 'left', cellWidth:10 },  // Position
            1: { halign: 'left' },  // Bezeichnung
            2: { halign: 'right', cellWidth:25 }, // Menge
            3: { halign: 'right', cellWidth:32 }, // Preis
            4: { halign: 'right', cellWidth:25 }  // Gesamt
        },
        didParseCell: function (data) {
            if (data.row.index === 0 && (data.column.index === 2 || data.column.index === 3 || data.column.index === 4)) {
                data.cell.styles.halign = 'right';
            }
            if (data.row.index === data.table.body.length - 1) {
                data.cell.styles.fillColor=255
            }
            
        },
    })

    // SECTION: get height of table
    let tablePositionFinalY = (doc as any).lastAutoTable.finalY;
    tablePositionFinalY=Number(tablePositionFinalY.toFixed());
    
    // SECTION: new page if tablePositionFinalY>=225
    if (tablePositionFinalY>=225) {
        doc.addPage()
        if (view&&status<10) {
            doc.setFontSize(50);
            doc.setTextColor(150);
            doc.text('PREVIEW',20, 30,{angle:-45});
            doc.text('PREVIEW',120, 30,{angle:-45});
            doc.setTextColor(0);
        }
        tablePositionFinalY=50
    }

    // Extra text
    doc.setFontSize(10);
    doc.setFont('helvetica', 'bold');
    doc.text(i18next.t('paymentTerms'),14,tablePositionFinalY+15)
    doc.setFont('helvetica', 'normal');
    doc.text(i18next.t('paymentInfo',{days:invoice.payment_days}),14,tablePositionFinalY+20)
    if (invoice.extra_text) {
        const invoice_extra_text = doc.splitTextToSize(invoice.extra_text, 180)
        doc.text(invoice_extra_text,14,tablePositionFinalY+30)
        // doc.text(invoice.extra_text,14,tablePositionFinalY+30)
    }
    
    const bankDetails = i18next.t('recipient')+': '+invoice.users.name+'\n'+i18next.t('bank')+': '+invoice.users.bank+'\n'+i18next.t('bankaccount')+': '+invoice.users.bankaccount;

    // SECTION: add bank details and page number everywhere
    for (let i = 1; i <= doc.getNumberOfPages(); i++) {
        // doc.addImage(imgData, 'png', 177, 284, 19, 6)
        doc.setPage(i)
        doc.addImage(imgData, 'png', 177, 10, 19, 6)
        doc.setFontSize(10);
        doc.line(14, 260, 196, 260);
        doc.setFont('helvetica', 'bold');
        doc.text(i18next.t('bankDetails'),14,270)
        doc.setFont('helvetica', 'normal');
        doc.text(bankDetails,14,275)
        doc.text(i18next.t('page')+' '+i+'/'+doc.getNumberOfPages(), 180, 283)
    }
    
    // Open the PDF
    if (view) {
        const pdfBlob = doc.output('blob');
        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl, '_blank');
    }
    // Save the PDF
    else {
        doc.save(invoice.description+".pdf");
    }
};

export const allToFixed = (number:number) => {
    const decimalSeparator = ','
    const thousandSeparator = '.'
    // Format the number to fixed decimals
    // console.log(number);
    if (number===undefined||number===null) number=0;
    
    let fixedNumber = number.toFixed(2);

    // Split the number into integer and fractional parts
    let [integerPart, fractionalPart] = fixedNumber.split('.');

    // Add thousand separators to the integer part
    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator);

    // Combine the integer part and the fractional part with the custom decimal separator
    if (fractionalPart !== undefined) {
        return integerPart + decimalSeparator + fractionalPart;
    } else {
        return integerPart;
    }
}

export const sendError = async (error:any) => {
    const cleanError=JSON.stringify(error,null,2)
    console.log(cleanError);
    // sendTelegramError(cleanError);
}

export const getDaysBefore = (x:number) => {
    const daysBeforeTmp = new Date();
    daysBeforeTmp.setDate(daysBeforeTmp.getDate() - x);
    const daysBefore: any = daysBeforeTmp.getFullYear()+'-'+(daysBeforeTmp.getMonth() + 1).toString().padStart(2, '0')+'-'+daysBeforeTmp.getDate().toString().padStart(2, '0');
    return daysBefore;
}