import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
// import HttpBackend from "i18next-http-backend"; // *** added this ***

i18n
.use(LanguageDetector)
.use(initReactI18next)
.init({
    // debug: true,
    fallbackLng: 'en',
    supportedLngs: ["en", "de", "en-GB", "de-DE"], // *** added this ***
    interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
        en: {
            translation: {
                titleAdder:"invoice creation for small bussineses",
                new:"new",
                date:"Date",
                note:"Note",
                delete:"delete",
                noClientsAvailable:"No clients available",
                noInvoicesAvailable:"No invoices available",
                clientNotFound:"Clients not found",
                clients:"Clients",
                client:"Client",
                invoices:"Invoices",
                invoice:"Invoice",
                ownSettings:"Settings",
                msgAfterLogin:"Please check your Emails",
                close:"Close",
                continue:"Continue",
                for:"For",
                pdfView:"View PDF",
                pdfDownload:"Download PDF",
                startNow:"Start Now",
                month:"month",
                increaseSoon:"(will increase soon)",
                saved:"Saved",
                search:"Search",
                save:"save",
                income:"Income",
                startToday:"Start today",
                pleaseLogin:"Please Login first",
                createAccount:"If not done yet, please create an account",
                tos:"Terms & Services",
                tosContent:"Terms & Services",
                privacyPolicy:"Privacy Policy",
                imprint:"Imprint",
                back:"zurück",
                setPassword:"set password",
                registerInfo:"For sign up or when you have not set a password, just leave the field blank",
                password:"Password",
                password2:"Repeat password",
                // Client
                phone:"Phone",
                email:"E-Mail",
                address:"Address",
                tax:"VAT",
                taxId:"VAT ID",
                taxNumber:"Tax number",
                name:"Name",
                zip:"Zip",
                street:"Street",
                streetNumber:"Street number",
                country:"Country",
                city:"City",
                newClient:"New client",
                editClient:"Edit client",
                clientNotes:"Client notes",
                // Invoice
                empty:"Empty",
                newInvoice:"New invoice",
                paymentDeadline:"Payment deadline",
                paymentTerms:"Payment terms",
                paymentInfo:"Please transfer the invoice amount within {{days}} days.",
                invoiceDate:"Invoice date",
                subTotal:"Subtotal",
                taxPercent:"tax in percent",
                invoiceID:"Invoice-ID",
                noTax:"no VAT",
                extra_text:"Extra text",
                positions:"Positions",
                position:"Position",
                description:"Description",
                amount:"Amount",
                price:"Price",
                total:"Total",
                last30:"last 30 days",
                makeFinal:"Finalize",
                makeFinalMsg:"By finalizing the invoice you can not make edits anymore.",
                edit:"edit",
                final:"Final",
                paid:"Paid",
                sent:"Sent",
                draft:"Draft",
                created:"Created",
                rejected:"Rejected",
                deleted:"Deleted",
                makePaid:"Set invoice to paid",
                makePaidMsg:"Setting the invoice to paid can not be undone.",
                makeReject:"Set invoice to rejected",
                makeRejectMsg:"Setting the invoice to rejected can not be undone.",
                makeSent:"Set invoice to sent",
                makeSentMsg:"Setting the invoice to sent can not be undone.",
                makeDelete:"Delete invoice",
                makeDeleteMsg:"Deleting the invoice can not be undone.",
                noTaxMessage:"Not subject to sales tax",
                page:"Page",
                copy:"Copy",
                public:"Create",
                noPublic:"Remove",
                makePublic:"Publish invoice",
                makePublicMsg:"If you want to send the invoice to the customer via a link, make the invoice public. Only people with the link can open the invoice. The link is automatically deactivated after 7 days.",
                open:"Open",
                paymentReminder:"Payment reminder",
                templateTexts:"Template texts",
                reminder:"Reminder",
                status:"Status",
                pickCustomer:"Pick customer",
                endingInvoiceText:"Final text",
                // Expenses
                expenses:"Expenses",
                editExpense:"edit expense",
                newExpense:"new expense",
                // User
                recipient:"Recipient",
                bankDetails:"Bank details",
                bankaccount:"Bank account number",
                bank:"Name of bank",
                currency:"Currency",
                lang:"Language",
                english:"English",
                german:"German",
                // Landingpage
                manageClients:"Manage clients",
                automaticInvoice:"Invoice automation",
                openInvoice:"Manage open invoices",
                moreSoon:"more soon",
                yourInvoiceIn:"Your invoice in seconds ",
                withCraftbills:"simple and fast with CraftBills",
                seconds:"seconds",
                // questions
                question1: "What is CraftBills and who is it for?",
                answer1: "CraftBills is a simple and fast invoicing tool, specifically designed for small business owners and freelancers. It helps you create invoices efficiently and simplify your accounting.",
                question2: "How do I add a new customer?",
                answer2: "Adding a new customer is very easy. Log in to your CraftBills account, go to the 'Customers' section, and click on 'New'. Fill in the required information and save the new customer.",
                question3: "How do I create an invoice for a customer?",
                answer3: "To create an invoice, first select the customer from your list. Then, under Invoices, click on 'New', enter the details of the service or product, add the prices, and save the invoice. You can then view it online and download it as a PDF.",
                question4: "What does CraftBills cost and is there a free trial version?",
                answer4: "Currently, CraftBills is still in the beta phase. All features are available for free. After the beta phase, CraftBills will offer various pricing plans tailored to the needs of small business owners and freelancers. There will also be a free trial version so you can try all the features and see how it works before deciding.",
                register:"Register now",
                // Features
                feature1:"Manage Customers",
                feature11:"This feature allows you to store and manage all customer data in one central location, including contact information. With an organized display, customer profiles can be easily updated, and relevant information can be quickly retrieved.",
                feature2:"Simple Invoice Creation",
                feature21:"This feature enables the automatic generation of invoices based on services rendered or products delivered, minimizing manual input and reducing errors. It allows the addition of specific details such as discounts and taxes.",
                feature3:"Manage Outstanding Invoices",
                feature31:"This feature provides a comprehensive overview of all unpaid invoices, ensuring that no payment is overlooked. Users can track payment statuses and keep an eye on due dates to take timely action.",
                feature4:"Send Payment Reminders",
                feature41:"This feature allows you to send payment reminders to customers with overdue invoices. It speeds up the payment process and improves the cash flow by efficiently and politely reminding customers of their outstanding balances.",
            }
        },
        de: {
            translation: {
                titleAdder:"Rechnungsstellung für Kleinunternehmer",
                new:"neu",
                date:"Datum",
                note:"Notiz",
                delete:"löschen",
                noClientsAvailable:"Keine Kunden verfügbar",
                noInvoicesAvailable:"Keine Rechnungen verfügbar",
                clientNotFound:"Clients not found",
                clients:"Kunden",
                client:"Kunde",
                invoices:"Rechnungen",
                invoice:"Rechnung",
                ownSettings:"Einstellungen",
                msgAfterLogin:"Bitte prüfe deine Emails",
                close:"Schließen",
                continue:"Weiter",
                for:"Für",
                pdfView:"PDF ansehen",
                pdfDownload:"PDF herunterladen",
                startNow:"Jetzt Starten",
                month:"Monat",
                increaseSoon:"(wird bald erhöht)",
                saved:"Gespeichert",
                search:"Suchen",
                save:"Speichern",
                income:"Einnahmen",
                startToday:"Starte heute",
                pleaseLogin:"Bitte logge dich zuerst ein",
                createAccount:"Wenn noch nicht erledigt, bitte erstelle einen Account",
                tos:"Nutzungsbedingungen",
                privacyPolicy:"Datenschutzerklärung",
                imprint:"Impressum",
                back:"zurück",
                setPassword:"Passwort setzen",
                registerInfo:"Zum Registrieren oder wenn du noch kein Passwort festgelegt hast, lass das Feld einfach leer.",
                password:"Passwort",
                password2:"Passwort wiederholen",
                // Client
                phone:"Telefon",
                email:"E-Mail",
                address:"Adresse",
                tax:"USt.",
                taxId:"USt-ID",
                taxNumber:"Steuernummer",
                name:"Name",
                zip:"PLZ",
                street:"Straße",
                streetNumber:"Hausnummer",
                country:"Land",
                city:"Stadt",
                newClient:"Neuer Kunde",
                editClient:"Kunde bearbeiten",
                clientNotes:"Kundenhinweise",

                // Invoice
                empty:"Leer",
                newInvoice:"Neue Rechnung",
                paymentDeadline:"Zahlungsziel",
                paymentTerms:"Zahlungsbedingungen",
                paymentInfo:"Bitte überweisen Sie den Rechnungsbetrag innerhalb von {{days}} Tagen.",
                invoiceDate:"Rechnungsdatum",
                subTotal:"Zwischensumme",
                taxPercent:"Steuer in Prozent",
                invoiceID:"Rechnungs-ID",
                noTax:"ohne USt",
                extra_text:"Abschließender Text",
                positions:"Positionen",
                position:"Position",
                description:"Beschreibung",
                amount:"Anzahl",
                price:"Preis",
                total:"Gesamt",
                last30:"letzte 30 Tage",
                makeFinal:"Rechnung abschließen",
                makeFinalMsg:"Durch Abschließen der Rechnung, kannst du keine Änderungen mehr vornehmen.",
                edit:"Bearbeiten",
                final:"Abschließen",
                paid:"Bezahlt",
                sent:"Gesendet",
                draft:"Entwurf",
                created:"Erstellt",
                rejected:"Abgelehnt",
                deleted:"Gelöscht",
                makePaid:"Rechnung als bezahlt markieren",
                makePaidMsg:"Die Rechnung als bezahlt markieren, kann nicht rückgängig gemacht werden.",
                makeReject:"Rechnung als abgelehnt markieren",
                makeRejectMsg:"Die Rechnung als abgelehnt markieren, kann nicht rückgängig gemacht werden.",
                makeSent:"Rechnung als gesendet markieren",
                makeSentMsg:"Die Rechnung als gesendet markieren, kann nicht rückgängig gemacht werden.",
                makeDelete:"Rechnung löschen",
                makeDeleteMsg:"Das Löschen der Rechnung kann nicht rückgängig gemacht werden.",
                noTaxMessage:"Nach §19 Abs. 1 UStG nicht umsatzsteuerpflichtig.",
                page:"Seite",
                copy:"Kopieren",
                public:"Erstellen",
                noPublic:"Entfernen",
                makePublic:"Rechnung veröffentlichen",
                makePublicMsg:"Wenn du die Rechnung per Link an den Kunden schicken möchtest, mache die Rechnung öffentlich. Nur Personen mit Link können die Rechnung öffnen. Der Link wird nach 7 Tagen automatisch wieder deaktiviert.",
                open:"Offen",
                paymentReminder:"Zahlungserinnerung",
                templateTexts:"Vorlagentexte",
                reminder:"Erinnerung",
                status:"Status",
                pickCustomer:"Kunde auswählen",
                endingInvoiceText:"Ablschusstext",
                // Expenses
                expenses:"Ausgaben",
                editExpense:"Ausgabe bearbeiten",
                newExpense:"neue Ausgabe",
                // User
                recipient:"Empfänger",
                bankDetails:"Bankverbindung",
                bankaccount:"IBAN",
                bank:"Name der Bank",
                currency:"Währung",
                lang:"Sprache",
                english:"Englisch",
                german:"Deutsch",
                // Landingpage
                manageClients:"Kunden verwalten",
                automaticInvoice:"Automatische Rechnungserstellung",
                openInvoice:"Offene Rechnungen verwalten",
                moreSoon:"mehr kommt bald",
                yourInvoiceIn:"Deine Rechnung in Sekunden ",
                withCraftbills:"Einfach und schnell mit CraftBills",
                seconds:"Sekunden",
                // questions
                question1:"Was ist CraftBills und für wen ist es gedacht?",
                answer1:"CraftBills ist ein einfaches und schnelles Tool zur Rechnungserstellung, speziell entwickelt für Kleinunternehmer und Freelancer. Es hilft dir, effizient Rechnungen zu erstellen und deine Buchhaltung zu vereinfachen.",
                question2:"Wie lege ich einen neuen Kunden an?",
                answer2:"Das Anlegen eines neuen Kunden ist ganz einfach. Logge dich in dein CraftBills-Konto ein, gehe zum Abschnitt \"Kunden\" und klicke auf \"Neu\". Fülle die erforderlichen Informationen aus und speichere den neuen Kunden.",
                question3:"Wie erstellt man eine Rechnung für Kunden mit CraftBills?",
                answer3:"Um eine Rechnung zu erstellen, wähle zunächst den Kunden aus deiner Liste aus. Klicke dann unter Rechnungen auf \"Neu\", gib die Details der Dienstleistung oder des Produkts ein, füge die Preise hinzu und speichere die Rechnung. Du kannst sie dann online ansehen und als PDF herunterladen.",
                question4:"Was kostet CraftBills und gibt es eine kostenlose Testversion?",
                answer4:"Aktuell befindet sich CraftBills noch in der Beta Phase. Alle Funktionen sind kostenlos nutzbar. Nach der Beta Phase wird CraftBills verschiedene Preispläne anbieten, die auf die Bedürfnisse von Kleinunternehmern und Freelancern zugeschnitten sind. Es wird auch  eine kostenlose Testversion geben, damit du alle Funktionen ausprobieren und sehen kannst, bevor du dich entscheidest.",
                register:"Jetzt registrieren",
                // Features
                feature1:"Kunden effizient verwalten mit CraftBills",
                feature11:"Diese Funktion ermöglicht es, alle Kundendaten an einem zentralen Ort zu speichern und zu verwalten, einschließlich Kontaktdaten. Durch eine übersichtliche Darstellung können Kundenprofile einfach aktualisiert und relevante Informationen schnell abgerufen werden.",
                feature2:"Einfache und schnelle Rechnungserstellung",
                feature21:"Mit dieser Funktion können Rechnungen basierend auf erbrachten Leistungen oder gelieferten Produkten automatisch generiert werden, wodurch manuelle Eingaben minimiert und Fehler vermieden werden. Sie das Hinzufügen von spezifischen Details wie Rabatten und Steuern.",
                feature3:"Offene Rechnungen verwalten",
                feature31:"Diese Funktion bietet eine umfassende Übersicht über alle noch unbezahlten Rechnungen, wodurch sichergestellt wird, dass keine Zahlung übersehen wird. Nutzer können den Zahlungsstatus verfolgen und Fälligkeiten im Blick behalten, um rechtzeitig Maßnahmen zu ergreifen.",
                feature4:"Zahlungserinnerung verschicken",
                feature41:"Diese Funktion ermöglicht es, Zahlungserinnerungen an Kunden zu senden, deren Rechnungen überfällig sind. Dadurch wird der Zahlungsprozess beschleunigt und die Liquidität verbessert, indem Kunden freundlich und effizient an ihre offenen Beträge erinnert werden.",
            }
        }
    }
});

export default i18n;