import React, { useEffect, useState } from 'react'
import { v4 } from 'uuid';
import supabase from '../../utils/supabase';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import Loading from '../../components/Loading';
import { useTranslation } from 'react-i18next';
import InvoiceEditNew from '../../components/InvoiceEditNew';
import { sendError } from '../../utils/functions';
import LinkPath from '../../components/LinkPath';
import Layout from '../../components/Layout';

export default function InvoiceEdit() {
    const { t } = useTranslation();
    const navigate = useNavigate()
    const {id:invoice_id} = useParams()
    const [loaded, setLoaded] = useState(false);
    const [positions, setPositions] = useState<any[]>([]);
    const [invoice, setInvoice] = useState<Invoices>();

    useEffect(()=>{;
        const getData = async () => {
            const {data, error} = await supabase.from('invoices').select('*').eq('id', invoice_id).single();
            if (data) {
                setInvoice(data);
                setPositions(data.positions)
            }
            else {
                sendError(error);
            }
            setLoaded(true);
        }
        getData();
    },[invoice_id])

    const handleEdit = (e:any) => {
        setInvoice({
            ...invoice,
            [e.target.name]: e.target.value
        });
    }
    const handleCheck = (e:any) => {
        setInvoice({
            ...invoice,
            [e.target.name]: e.target.checked
        });
    }
    const handleDropdown = (e:any) => {
        setInvoice({
            ...invoice,
            [e.target.name]: e.target.value
        });
    }
    const handlePositionDescriptionChange = (id: string, newText: string) => {
        setPositions((prevPos:any[]) =>
            prevPos.map(pos =>
                pos.id === id ? { ...pos, description: newText } : pos
            )
        );
    }
    const handlePositionAmountChange = (id: string, newAmount: string) => {
        setPositions((prevPos:any[]) =>
            prevPos.map(pos =>
                pos.id === id ? { ...pos, amount: Number(newAmount) } : pos
            )
        );
    }
    const handlePositionPriceChange = (id: string, newPrice: string) => {
        setPositions((prevPos:any[]) =>
            prevPos.map(pos =>
                pos.id === id ? { ...pos, price: Number(newPrice) } : pos
            )
        );
    }
    const handleAddNewPosition = () => {
        const newId = v4()
        setPositions((prevPos:any[]) => [...prevPos, { id: newId, description: '', amount: 1, price: '' }]);
    };
    const handleDeletePosition = (id: string) => {
        setPositions((prevPos:any[]) => prevPos.filter(link => link.id !== id));
    };

    const save = async (e:any) => {
        e.preventDefault()
        const {data, error} = await supabase.from('invoices').update({
            description:invoice.description,
            tax_percent:Number(invoice.tax_percent),
            positions:positions,
            payment_days:Number(invoice.payment_days),
            extra_text:invoice.extra_text
        }).eq("id",invoice.id).select().single();
        if (data) {
            navigate('/invoices/'+data.id)
        }
        else {
            sendError(error);
        }
    }

    return loaded ? (
        invoice ?
            <Layout>
                <LinkPath path={[{'name':'invoices','link':'invoices'},{'name':invoice.description,'link':'invoices/'+invoice.id},{'name':'edit','link':'invoices'}]}/>
                <h1 className="text-3xl md:text-4xl font-extrabold">{t('for')} {invoice.address.name}</h1>
                <InvoiceEditNew invoice={invoice} save={save} handleEdit={handleEdit} handleCheck={handleCheck} handleDropdown={handleDropdown} positions={positions} handlePositionDescriptionChange={handlePositionDescriptionChange} handlePositionAmountChange={handlePositionAmountChange} handlePositionPriceChange={handlePositionPriceChange} handleDeletePosition={handleDeletePosition} handleAddNewPosition={handleAddNewPosition} />
            </Layout>
        :
            <Navigate to="/" />

    ) : (
        <Loading />
    )
}
