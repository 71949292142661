import { Save } from 'lucide-react';
import React from 'react'
import { useTranslation } from 'react-i18next';

export default function ClientEditNew({client, handleEdit, save}:{client:Clients, handleEdit:any, save:any}) {
    const { t } = useTranslation();

    return (
        <form className="w-full max-w-3xls" onSubmit={save}>
            <label className="form-control w-full">
                <div className="label"><span className="label-text">{t('name')}</span></div>
                <input type="text" placeholder={t('name')} className="input input-bordered w-full max-w-xss" name='name' value={client.name} onChange={handleEdit} />
            </label>
            <label className="form-control w-full">
                <div className="label"><span className="label-text">{t('phone')}</span></div>
                <input type="text" placeholder={t('phone')} className="input input-bordered w-full max-w-xss" name='phone' value={client.phone} onChange={handleEdit} />
            </label>
            <label className="form-control w-full">
                <div className="label"><span className="label-text">{t('email')}</span></div>
                <input type="email" placeholder={t('email')} className="input input-bordered w-full max-w-xss" name='email' value={client.email} onChange={handleEdit} />
            </label>
            <label className="form-control w-full">
                <div className="label"><span className="label-text">{t('taxId')}</span></div>
                <input type="text" placeholder={t('taxId')} className="input input-bordered w-full max-w-xss" name='ust_id' value={client.ust_id} onChange={handleEdit} />
            </label>
            <div className="divider"></div>
            <div className='flex flex-col md:flex-row md:gap-2'>
                <label className="form-control w-full md:w-8/12">
                    <div className="label"><span className="label-text">{t('street')}</span></div>
                    <input type="text" placeholder={t('street')} className="input input-bordered w-full max-w-xss" name='street' value={client.street} onChange={handleEdit} />
                </label>
                <label className="form-control w-full md:w-4/12">
                    <div className="label"><span className="label-text">{t('streetNumber')}</span></div>
                    <input type="text" placeholder={t('streetNumber')} className="input input-bordered w-full max-w-xss" name='streetnumber' value={client.streetnumber} onChange={handleEdit} />
                </label>
            </div>
            <label className="form-control w-full">
                <div className="label"><span className="label-text">{t('zip')}</span></div>
                <input type="text" placeholder={t('zip')} className="input input-bordered w-full max-w-xss" name='zip' value={client.zip} onChange={handleEdit} />
            </label>
            <label className="form-control w-full">
                <div className="label"><span className="label-text">{t('city')}</span></div>
                <input type="text" placeholder={t('city')} className="input input-bordered w-full max-w-xss" name='city' value={client.city} onChange={handleEdit} />
            </label>
            <label className="form-control w-full">
                <div className="label"><span className="label-text">{t('country')}</span></div>
                <input type="text" placeholder={t('country')} className="input input-bordered w-full max-w-xss" name='country' value={client.country} onChange={handleEdit} />
            </label>
            <div className="divider"></div>
            <label className="form-control">
                <div className="label"><span className="label-text">{t('clientNotes')}</span></div>
                <textarea className="textarea textarea-bordered h-24" placeholder={t('clientNotes')} name='info' value={client.info} onChange={handleEdit}>{client.info}</textarea>
            </label>
            <div className='mt-3'>
                <button className='btn btn-primary' type="submit"><Save />{t('save')}</button>
            </div>
        </form>
    )
}
